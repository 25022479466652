import { HttpRoute } from "@appti/core";

import {
	ApplicationTemplateDto,
	ApplicationTemplateQueryDto,
	ApplicationTemplateQueryResultsDto,
} from "./dtos";

/**
 * Create routes definitions for `application-template` features
 *
 * @param base of the HttpRoute builder
 * @returns route definitions
 */
export function createTemplateHttpDefinitions<
	const BASE extends HttpRoute.BuilderAny,
>(base: BASE) {
	const pathById = base.addSegment({
		param: "templateId",
		type: "param",
		validation: "number",
	});

	return {
		findAndCount:
			base.get<
				(
					query: ApplicationTemplateQueryDto,
				) => Promise<ApplicationTemplateQueryResultsDto>
			>(),
		findById: pathById.get<() => Promise<ApplicationTemplateDto>>(),
	} as const satisfies HttpRoute.Definitions;
}
