import { Model } from "@appti/core";
import * as z from "zod";

import { organizationModelSchema } from "../../organization/organization.model";

/** Validation schema for {@link ApplicationTemplateModel} */
export const applicationTemplateModelSchema = Model.schema.extend({
	// Note: current a ManyToOne, but could be a Many2Many later
	fkOrganization: organizationModelSchema.shape._id.describe(
		"Organization owning the template",
	),

	description: z.string().describe("Description of the template"),
});

/**
 * The application template (with version)
 * This is the reference for all its version and its statuses
 */
export type ApplicationTemplateModel = z.infer<
	typeof applicationTemplateModelSchema
>;
