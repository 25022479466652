import { HttpRoute } from "@appti/core";

import {
	ApplicationInstanceCountedByStatuses,
	ApplicationInstanceCountedByStatusesQuery,
	ApplicationInstanceCreateDto,
	ApplicationInstanceDto,
	ApplicationInstanceQueryDto,
	ApplicationInstanceQueryResultsDto,
	ApplicationValueDto,
} from "./dtos";
import { createGroupCheckHttpDefinitions } from "./group-check/group-check.http";

/**
 * Create routes definitions for `instance` features
 *
 * @param base of the HttpRoute builder
 * @returns route definitions
 */
export function createInstanceHttpDefinitions<
	const BASE extends HttpRoute.BuilderAny,
>(base: BASE) {
	const pathById = base.addSegment({
		param: "instanceId",
		type: "param",
		validation: "number",
	});

	return {
		children: {
			// (not matters the permissions)
			groupChecks: createGroupCheckHttpDefinitions(
				pathById.addSegment("group-check"),
			),
		},
		/** Retrieve the total of instance for each statuses */
		countByStatuses: base
			// This can be removed and by done N (statuses) requests
			.addSegment("count-statuses")
			.get<
				(
					query: ApplicationInstanceQueryDto,
				) => Promise<ApplicationInstanceCountedByStatuses>
			>(),
		create: base.post<
			(
				query: ApplicationInstanceCreateDto,
			) => Promise<ApplicationInstanceDto>
		>(),
		findAndCount:
			base.get<
				(
					query: ApplicationInstanceCountedByStatusesQuery,
				) => Promise<ApplicationInstanceQueryResultsDto>
			>(),
		findOne: pathById.get<() => Promise<ApplicationInstanceDto>>(),
		getInputValues: pathById
			.addSegment({
				path: "values",
				type: "path",
			})
			.get<() => Promise<ApplicationValueDto[]>>(),
		updateInput: pathById
			.addSegment({
				path: "inputs",
				type: "path",
			})
			.patch<() => Promise<ApplicationValueDto[]>>(),
		updateStatus: pathById
			.addSegment({
				path: "status",
				type: "path",
			})
			.patch<() => Promise<ApplicationInstanceDto>>(),
		validate: pathById
			.addSegment({
				path: "validate",
				type: "path",
			})
			.post<() => Promise<void>>(),
	} as const;
}
