import { styled } from "@mui/joy";
import { Field, FieldInputProps, FieldProps } from "formik";
import React, { CSSProperties, ReactNode } from "react";

import { ErrorMessage } from "./ErrorMessage";
import { DotPath } from "../../../../utils/types/DotPath";
import { Icon, IconName } from "../../../ui-atoms/components/Icons/Icon";
import { Tooltip } from "../../../ui-atoms/components/Tooltip";
import { textSmallMedium } from "../../../ui-layout/styles/textStyles";

interface FormElementWrapperProps<T = string> {
	children: ReactNode;
	disabled?: boolean;
	hideError?: boolean;
	hideLabel?: boolean;
	label?: string;
	layout?: "horizontal" | "vertical";
	names: Array<T extends string ? T : T extends object ? DotPath<T> : string>;
	required?: boolean;
	styles?: CSSProperties;
	subLabel?: string;
	tooltip?: string;
	wrapElements?: boolean;
}

/**
 * Wrapper for formik form inputs
 *
 * @param props The props
 * @param props.children The form input component(s)
 * @param props.disabled Whether the form element is disabled
 * @param props.hideError Whether to hide the error message
 * @param props.hideLabel Whether to hide the label
 * @param props.label The label of the form element
 * @param props.layout The layout of the form inputs
 * @param props.names The names of the form elements (if multiple inputs)
 * @param props.required Whether the form element is required
 * @param props.styles The styles to apply to the form element
 * @param props.tooltip The tooltip to display
 * @param props.wrapElements Whether to wrap the form elements (This is a hack, because of the ProvisionsRow input overflowing)
 * @returns The component
 */
export const FormElementWrapper = <T,>({
	children,
	disabled,
	hideError,
	hideLabel,
	label,
	layout = "vertical",
	names,
	required,
	styles,
	tooltip,
	wrapElements = true,
}: FormElementWrapperProps<T>): JSX.Element => (
	<FormElementContainer style={styles}>
		{!hideLabel && (
			<LabelStyled disabled={disabled} htmlFor={names.join(",")}>
				{label}
				{required && <RequiredStyled>*</RequiredStyled>}
				{tooltip && (
					<TooltipStyled title={tooltip}>
						<span aria-label="info" role="img">
							<Icon name={IconName.information} />
						</span>
					</TooltipStyled>
				)}
			</LabelStyled>
		)}

		<FormInputsContainer layout={layout} wrapElements={wrapElements}>
			{React.Children.map(children, (child, idx) => {
				if (
					React.isValidElement<
						Partial<FieldInputProps<unknown>> & {
							disabled?: boolean;
							error?: boolean;
							required?: boolean;
						}
					>(child)
				) {
					return (
						<div>
							<Field name={names[idx]}>
								{({ field, meta }: FieldProps<T>) =>
									React.cloneElement(child, {
										...field,
										disabled,
										error: meta.touched && !!meta.error,
										required,
										value: field.value ?? "",
									})
								}
							</Field>
						</div>
					);
				}

				return null;
			})}
		</FormInputsContainer>

		{!hideError && (
			<div>
				{names.map(fieldName => (
					<ErrorMessage<T> key={fieldName} name={fieldName} />
				))}
			</div>
		)}
	</FormElementContainer>
);

const FormElementContainer = styled("div")`
	display: flex;
	flex-direction: column;

	width: 100%;
`;

const LabelStyled = styled("label")<{ disabled?: boolean }>`
	display: flex;
	column-gap: 4px;

	margin-bottom: 4px;

	${textSmallMedium}
	color: ${({ disabled, theme }) =>
		disabled ? theme.palette.grey[4] : theme.palette.grey.text};
`;

const FormInputsContainer = styled("div")<{
	layout: "horizontal" | "vertical";
	wrapElements: boolean;
}>`
	display: flex;
	flex-direction: ${({ layout }) =>
		layout === "horizontal" ? "row" : "column"};
	gap: ${({ layout }) => (layout === "horizontal" ? "16px" : "0")};
	flex-wrap: ${({ wrapElements }) => (wrapElements ? "wrap" : "nowrap")};
`;

export const RequiredStyled = styled("span")`
	color: ${({ theme }) => theme.palette.red[1]};
`;

const TooltipStyled = styled(Tooltip)`
	color: ${({ theme }) => theme.palette.grey[4]};
`;
