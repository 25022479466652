import { styled } from "@mui/joy";
import { ErrorMessage as ErrorMessageFormik } from "formik";
import { useTranslation } from "next-i18next";

import { textLabelSmall } from "../../../ui-layout/styles/textStyles";

// eslint-disable-next-line @typescript-eslint/no-unused-vars -- ignore
interface ErrorMessageProps<_T = unknown> {
	name: string;
}

export const ErrorMessage = <T = unknown,>({
	name,
}: ErrorMessageProps<T>): JSX.Element => {
	const { t } = useTranslation();

	return (
		<ErrorMessageFormik
			name={name}
			render={errorMessage => (
				<ErrorMessageStyled css={textLabelSmall}>
					{t(errorMessage)}
				</ErrorMessageStyled>
			)}
		/>
	);
};

const ErrorMessageStyled = styled("span")`
	padding-top: 12px;
	color: ${({ theme }) => theme.palette.red[3]};
`;
