import * as z from "zod";

import { ApplicationInstanceStep } from "./instance.step";

/** Validation schema for {@link ApplicationInstanceStatus} */
export const applicationInstanceStatusSchema = z.enum([
	"draft",
	"progress",
	"done",
	"cancelled",
]);
/** The status in a instance is deduced from its step */
export type ApplicationInstanceStatus = z.infer<
	typeof applicationInstanceStatusSchema
>;

/** The step of progress instance */
export type ApplicationInstanceProgressStep = z.infer<
	typeof applicationInstanceProgressStepSchema
>;

/** Statuses dict with its data (steps) */
const STATUS = {
	cancelled: { schema: z.enum(["cancelled"]) },
	done: { schema: z.enum(["done"]) },
	draft: { schema: z.enum(["draft"]) },
	progress: {
		schema: z.enum([
			"request_validation",
			"services_validation",
			"offer_validation",
			"pending_client_validation",
			"pending_event",
			"invoice_preparation",
			"pending_payment",
		]),
	},
} as const satisfies Record<
	ApplicationInstanceStatus,
	{ schema: z.ZodEnum<[string, ...string[]]> }
>;
export const applicationInstanceProgressStepSchema = STATUS.progress.schema;

/**
 * Mapping use to display correctly the status to client
 * TODO: Use it with front translation to remove duplication
 */
export const applicationInstanceStatusMapping: Record<
	ApplicationInstanceStep,
	string
> = {
	cancelled: "Annulé",
	done: "Terminé",
	draft: "Brouillon",
	invoice_preparation: "Préparation facturation",
	offer_validation: "Validation offre",
	pending_client_validation: "Attente confirmation client",
	pending_event: "Attente événement",
	pending_payment: "Attente paiement",
	request_validation: "Validation demande",
	services_validation: "Validation Services",
};
export { STATUS as APPLICATION_INSTANCE_STATUS };
