import { styled } from "@mui/joy";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useParams } from "next/navigation";
import { useRouter } from "next/router";
import { useTranslation } from "next-i18next";
import {
	createContext,
	Dispatch,
	ReactNode,
	SetStateAction,
	useEffect,
	useState,
} from "react";
import { AuthProfile } from "~/common/auth";

import { LayoutLogout } from "./LayoutLogout";
import { authRepository } from "../../../services/api/repositories/AuthRepository";
import { Spinner } from "../../ui-atoms/components/Spinner";
import { ContentWrapper } from "../components/ContentWrappers";
import { Header } from "../components/Header";
import { Logo } from "../components/Logo";
import { LogoutSection } from "../components/LogoutSection";
import { ParagraphCenter } from "../styles/textStyles";

interface LayoutLoggedInProps {
	children: ReactNode;
}

export const UserContext = createContext<{
	current: AuthProfile.Dto | null;
	setCurrentContext: Dispatch<SetStateAction<AuthProfile.Dto | null>>;
} | null>(null);
export const LayoutLoggedIn = ({
	children,
}: LayoutLoggedInProps): JSX.Element => {
	const { t } = useTranslation();
	const router = useRouter();
	const { organizationId } = useParams<{ organizationId: string }>();

	const [currentContext, setCurrentContext] =
		useState<AuthProfile.Dto | null>(null);

	// For initial loading screen
	const {
		data: profile,
		isSuccess: isSuccessProfile,
		refetch,
	} = useQuery(authRepository.getProfile());

	// For refreshing the token upon page changes
	const { mutate } = useMutation(authRepository.refresh());
	useEffect(() => {
		if (profile) {
			setCurrentContext(profile);
			void refetch();
		}
	}, [profile, refetch]);
	useEffect(() => {
		if (isSuccessProfile) {
			setCurrentContext(() => profile);
			mutate({
				cookie: true,
			});
		} else {
			setCurrentContext(null);
		}
	}, [isSuccessProfile, mutate, router.pathname, profile]);

	if (!isSuccessProfile) {
		return (
			<BodyWrapper>
				<SpinnerContainer>
					<Logo />
					<Spinner size="lg" />
				</SpinnerContainer>
			</BodyWrapper>
		);
	}

	// Verify if the user has access to the organizationId in the url param
	// We check groups + roles. If no access, display an error message.
	const hasRoleInOrg = profile?.user.roles.some(
		role => role.fkOrganization === +organizationId,
	);
	const hasGroupInOrg = profile?.user.groups.some(
		group => group.fkOrganization === +organizationId,
	);
	if (!hasRoleInOrg && !hasGroupInOrg) {
		return (
			<LayoutLogout title="pages.error.title">
				<LogoutSection title={t("pages.error.title")} withBackground>
					<ParagraphCenter>
						{t("pages.error.description-user-no-org")}
					</ParagraphCenter>
				</LogoutSection>
			</LayoutLogout>
		);
	}

	return (
		<UserContext.Provider
			value={{
				current: currentContext,
				setCurrentContext,
			}}
		>
			<BodyWrapper>
				<FirstHeaderWrapper>
					<Header />
					<SecondHeaderWrapper></SecondHeaderWrapper>
				</FirstHeaderWrapper>
				<ContentWrapper>{children}</ContentWrapper>
			</BodyWrapper>
		</UserContext.Provider>
	);
};

const BodyWrapper = styled("div")`
	min-height: 100vh;
	min-width: fit-content;
`;

const SpinnerContainer = styled("div")`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	height: 100vh;
	gap: 24px;
`;

const FirstHeaderWrapper = styled("div")`
	position: fixed;
	width: 100vw;

	z-index: var(--z-index-header);
	background: ${({ theme }) => theme.palette.white.white};
`;

const SecondHeaderWrapper = styled("div")`
	margin: 0 auto;
	width: 100vw;
	max-width: var(--width-content-max);
`;
