import { createQueryResultsSchema } from "@appti/core";
import * as z from "zod";

import { userDtoSchema } from "./user.dto";

/** Validation schema for {@link UserQueryResultsDto} */
export const userQueryResultsSchema = createQueryResultsSchema(userDtoSchema);

/** Results for {@link UserDto} for a query */
export type UserQueryResultsDto = z.infer<typeof userQueryResultsSchema>;
