import * as z from "zod";

import * as Options from "./options";
import { BaseModel, DISCRIMINATION_KEY } from "./options/base";

/** Validation schema for {@link Model} */
export const schema = z.discriminatedUnion(DISCRIMINATION_KEY, [
	Options.Input.schema,
	Options.Paragraph.schema,
	Options.Section.schema,
	Options.Supply.schema,
]);

/** Specification of an element (defines its behavior) */
export type Model = z.infer<typeof schema>;

/** Known type for {@link Model} */
export type Type = Model[typeof DISCRIMINATION_KEY];
/** List of available types for {@link Model} */
export const TYPES: readonly Type[] = schema.options.map(
	({ shape }) => shape[DISCRIMINATION_KEY].value,
);

/** Common model for all Options */
export type Common = BaseModel<Type>;
