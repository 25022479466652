import { apiApplicationInstanceEndpoints } from "./apiApplicationInstanceEndpoints";
import { apiApplicationTemplateEndpoints } from "./apiApplicationTemplateEndpoints";
import { apiApplicationVersionEndpoints } from "./apiApplicationVersionEndpoints";
import { apiAuthEndpoints } from "./apiAuthEndpoints";
import { apiOrganizationGroupEndpoints } from "./apiOrganizationGroupEndpoints";
import { apiUserOrganizationEndpoints } from "./apiOrganizationUserEndpoints";

export const apiEndpoints = {
	applicationInstances: apiApplicationInstanceEndpoints,
	applicationTemplates: apiApplicationTemplateEndpoints,
	applicationVersion: apiApplicationVersionEndpoints,
	auth: apiAuthEndpoints,
	organizationGroup: apiOrganizationGroupEndpoints,
	organizationUser: apiUserOrganizationEndpoints,
};
