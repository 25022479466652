/** Environment data about the communication with the backend API */
export interface EnvironmentBackendBase<T extends string> {
	/** Type of backend */
	type: T;
}

/** Backend data with "classic" data */
export interface EnvironmentBackendUrl
	extends EnvironmentBackendBase<"url">,
		EnvironmentBackendQuery {
	/** Base url to the server api (request directly send there), should not end with `/`. */
	url: string;
}

/** Backend data with the rewrite middleware */
export interface EnvironmentBackendRewrite
	extends EnvironmentBackendBase<"rewrite">,
		EnvironmentBackendQuery {
	/**
	 * Base url to the "real" server api (reachable from the server), should not end with `/`.
	 *
	 * It can be overwritten with a env var (`FE_BACKEND_REWRITE`) on server proxy
	 */
	rewrite: string;
}
/** Backend query parameters */
export interface EnvironmentBackendQuery {
	/** Value to query every element (this is a "quick hack" and should be improved later) */
	limitAll: 9999;
}

/** Environment data about the communication with the backend API */
export type EnvironmentBackend =
	| EnvironmentBackendRewrite
	| EnvironmentBackendUrl;

/** The "Environment" for frontend */
export interface Environment {
	/** Define the configuration to communicate with the backend API */
	backend: () => EnvironmentBackend;
	/** Define the logos for the different organizations */
	logos: Record<number, LogoMap>;
}

/** Map of the different logos per organization */
export enum LogoMap {
	MLV = "https://www.ideesport.ch/wp-content/uploads/2023/10/logo_milvignes62.png",
	VDT = "https://www.val-de-travers.ch/themes/bbt/images/logomobile.svg",
}
