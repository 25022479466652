import { useParams } from "next/navigation";
import { decode } from "~/common/application/instance/utils/instance-credentials";

import { useIsPublicRoute } from "../../ui-layout/services/useIsPublicRoute";

/**
 * Hook that returns the decoded applicationId and organizerEmail from the URL,
 * based on whether we are in a public route or not.
 *
 * @returns
 * applicationId: The decoded applicationId
 * organizerEmail: The organizer email
 * organizationId: The organizationId from the url (added to facilitated useQueries)
 */
export const useDecodedAppId = () => {
	const { applicationId: appIdFromUrl, organizationId } = useParams<{
		applicationId: string;
		organizationId: string;
	}>();
	const { isPublicRoute } = useIsPublicRoute();

	if (!isPublicRoute) {
		return {
			applicationId: +appIdFromUrl,
			organizationId: +organizationId,
			organizerEmail: undefined,
		};
	}

	const { email, id } = decode(appIdFromUrl);
	return {
		applicationId: +id,
		organizationId: +organizationId,
		organizerEmail: email,
	};
};
