import { Theme, Button as ButtonJoy, buttonClasses } from "@mui/joy";
import { Route } from "next";
import Link from "next/link";
import { ReactNode } from "react";
import { UrlObject } from "url";

import { theme } from "../../../ui-layout/styles/theme";

/** Variants for ButtonNav */
export enum ButtonNavVariant {
	DEFAULT = "DEFAULT",
	SIDE = "SIDE",
	THIN = "THIN",
}

/**
 * active: to show the button as active
 * disabled: to disable the button
 * endDecorator: to add a decorator at the end of the button
 * href: to make the button a link
 * label: the text to display on the button
 * onClick: the function to call when the button is clicked
 * startDecorator: to add a decorator at the start of the button
 */
export interface ButtonNavProps {
	active?: boolean;
	disabled?: boolean;
	endDecorator?: ReactNode;
	href?: UrlObject | string;
	label?: string;
	onClick?: () => void;
	startDecorator?: ReactNode;
	variant?: ButtonNavVariant;
}

export const ButtonNav = ({
	active,
	href,
	label,
	variant = ButtonNavVariant.DEFAULT,
	...rest
}: ButtonNavProps): JSX.Element => {
	const ButtonComp = (
		<ButtonJoy
			{...rest}
			sx={theme => getButtonNavStyle(theme, variant, active)}
		>
			{label}
		</ButtonJoy>
	);

	if (href) {
		return (
			<Link href={href as Route} passHref>
				{ButtonComp}
			</Link>
		);
	}

	return ButtonComp;
};

type StyleDef = {
	[variant in ButtonNavVariant]: {
		borderRadius: string;
		color: string;
		fontWeightActive: number;
		height: string;
		justifyContent: string;
		width: string;
	};
};

export const styles: StyleDef = {
	[ButtonNavVariant.DEFAULT]: {
		borderRadius: "8px",
		color: theme.vars.palette.grey[4],
		fontWeightActive: 600,
		height: "40px",
		justifyContent: "center",
		width: "fit-content",
	},
	[ButtonNavVariant.THIN]: {
		borderRadius: "4px",
		color: theme.vars.palette.grey[4],
		fontWeightActive: 500,
		height: "32px",
		justifyContent: "center",
		width: "fit-content",
	},
	[ButtonNavVariant.SIDE]: {
		borderRadius: "8px",
		color: theme.vars.palette.grey.text,
		fontWeightActive: 600,
		height: "40px",
		justifyContent: "start",
		width: "100%",
	},
};

function getButtonNavStyle(
	theme: Theme,
	variant: ButtonNavVariant,
	active?: boolean,
) {
	return {
		background: active
			? theme.vars.palette.blue[2]
			: theme.vars.palette.white.white,
		borderRadius: styles[variant].borderRadius,
		color: active ? theme.vars.palette.blue.primary : styles[variant].color,
		fontSize: "14px",
		fontWeight: active ? styles[variant].fontWeightActive : 500,
		height: styles[variant].borderRadius,
		justifyContent: styles[variant].justifyContent,
		padding: "0 16px",
		width: styles[variant].width,

		"&:hover": {
			background: active
				? theme.vars.palette.blue[2]
				: theme.vars.palette.grey[1],
		},

		[`&.${buttonClasses.disabled}`]: {
			background: theme.vars.palette.grey[3],
			color: theme.vars.palette.white.white,
			// TODO: fix cursor
			cursor: "not-allowed",
		},
	};
}
