import { styled } from "@mui/joy";
import { useMutation } from "@tanstack/react-query";
import { useRouter } from "next/router";
import { useTranslation } from "next-i18next";
import { useContext } from "react";

import { authRepository } from "../../../services/api/repositories/AuthRepository";
import { ROUTES } from "../../../services/routes";
import { Icon, IconName } from "../../ui-atoms/components/Icons/Icon";
import {
	MenuList,
	menuItemDefinition,
} from "../../ui-atoms/components/MenuList";
import { UserContext } from "../templates/LayoutLoggedIn";

export const UserMenu = (): JSX.Element => {
	const { t } = useTranslation();
	const router = useRouter();

	const userContext = useContext(UserContext);

	const { mutateAsync: mutateLogout } = useMutation({
		...authRepository.logout(),
		onSuccess: async () => {
			await router.push(ROUTES.auth.login.url());
		},
	});

	const menuItems: menuItemDefinition[] = [
		{
			href: ROUTES.users.account.url(),
			icon: IconName.userSettings,
			id: 1,
			label: t("menu.account"),
		},
		{
			icon: IconName.logout,
			id: 2,
			label: t("menu.logout"),
			onClick: () => {
				void mutateLogout();
			},
		},
	];

	const MenuButton = () => (
		<MenuButtonContainer>
			<Icon name={IconName.avatar} size={24} />
			<span>{`${userContext?.current?.user.name.first} ${userContext?.current?.user.name.last}`}</span>
		</MenuButtonContainer>
	);

	return (
		<div>
			<MenuList menuItems={menuItems}>
				<MenuButton />
			</MenuList>
		</div>
	);
};

const MenuButtonContainer = styled("div")`
	display: flex;
	align-items: center;
	gap: 4px;
`;
