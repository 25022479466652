import { styled } from "@mui/joy";
import { Tooltip as JoyTooltip, TooltipProps } from "@mui/joy";

export const Tooltip = ({
	children,
	placement = "top",
	...props
}: TooltipProps): JSX.Element => (
	<JoyTooltip placement={placement} {...props}>
		{/* The div wrapper is needed for some children where the tooltip doesn't appear otherwise (e.g. Button) */}
		<TooltipWrapper>{children}</TooltipWrapper>
	</JoyTooltip>
);

const TooltipWrapper = styled("div")`
	display: inline-block;
`;
