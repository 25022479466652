/** Single Route meta data */
export interface RouteMeta {
	/** Determine, with parameters, if the current route is active */
	isActive?: (...params: never[]) => boolean;
	/** Get a {@link string} to use with the Next router */
	url: (...params: never[]) => string;
}

/** Recursive structure for route definition */
type RouteDict = RouteMeta | { [K: string]: RouteDict };

/** All known page route */
export const ROUTES = {
	applications: {
		read: {
			root: {
				url: (applicationId: number) =>
					getUrl({
						pathname: "/applications/[applicationId]",
						query: { applicationId },
					}),
			},
			success: {
				isActive: (asPath: string) =>
					/applications\/.+\/success/.test(asPath),
				url: (applicationId: string) =>
					getUrl({
						pathname: "/applications/[applicationId]/success",
						query: { applicationId },
					}),
			},
			view: {
				isActive: (asPath: string) =>
					/applications\/.+\/view/.test(asPath),
				url: (applicationId: string) =>
					getUrl({
						pathname: "/applications/[applicationId]/view",
						query: { applicationId },
					}),
			},
		},
		root: {
			isActive: (asPath: string) => /applications(\/.+)?/.test(asPath),
			url: () => "/applications",
		},
		templates: {
			new: {
				url: (templateId: number) =>
					getUrl({
						pathname: "/applications/templates/[templateId]",
						query: { templateId },
					}),
			},
		},
	},

	home: {
		url: () => "/applications",
	},

	settings: {
		root: {
			url: () => "/settings",
		},
	},

	users: {
		account: {
			url: () => "/users/account",
		},
		root: {
			isActive: (asPath: string) => /users(\/\d+)?/.test(asPath),
			url: () => "/users",
		},
	},

	auth: {
		error: { url: () => "404" },
		forgotPassword: {
			url: () => "/forgot-password",
		},
		login: {
			url: () => "/login",
		},
		passwordReset: {
			url: () => "/reset",
		},
	},
} as const satisfies Record<string, RouteDict>;

interface RouteUrlObject {
	pathname: string;
	query: Record<string, string | number>;
}

/**
 * This function is a helper to convert our route object to a stringified URL.
 * We need this because we need to provide string-based URLs to <Link>, router.push, etc..
 * Otherwise, the orgRedirect middleware will add duplicated query params to the route.
 *
 * @param urlObject our route object
 * @returns a stringified URL
 */
function getUrl(urlObject: RouteUrlObject): string {
	let url = urlObject.pathname;
	const query = urlObject.query;

	const slugs: string[] = url.match(/\[.*?]/g) || [];
	for (const slug of slugs) {
		const key = slug.replace(/[[\]]/g, "");
		const value = query[key];
		if (value) {
			url = url.replace(slug, value.toString());
		}
	}

	const queryParams = Object.entries(query)
		.filter(([key, _]) => !slugs.includes(`[${key}]`))
		.map(([key, value]) => [key, value.toString()]);
	return `${url}?${new URLSearchParams(queryParams).toString()}`;
}
