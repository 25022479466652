import { Environment, LogoMap } from "./environment.interface";

/** The prod "environment" */
export const ENVIRONMENT: Environment = {
	// TODO: from `window.location` ?
	// We expect, on production environments, the backend to be directly accessible
	//	It reduces the load on the Next server
	backend: () => ({ limitAll: 9999, type: "url", url: "/api" }),
	logos: {
		2: LogoMap.VDT,
		3: LogoMap.MLV,
	},
};
