import { styled } from "@mui/joy";
import { useRouter } from "next/router";

import { Icon, IconName } from "../../ui-atoms/components/Icons/Icon";
import { Button, ButtonVariants } from "../../ui-form/components/Button/Button";
import { setForMobile, setForTablet } from "../styles/breakpoints";
import { textH1 } from "../styles/textStyles";
import { theme } from "../styles/theme";

type AlignItems = "stretch" | "center";

interface LogoutSectionProps {
	align?: AlignItems;
	backLink?: string;
	children: React.ReactNode;
	iconName?: IconName;
	iconStatus?: "error" | "success";
	large?: boolean;
	title?: string;
	withBackground?: boolean;
}

/**
 * Layout component to display a section within a logged out page
 *
 * @param props The props
 * @param props.align The alignment of the section items
 * @param props.backLink If sets, displays a button that goes back to this link
 * @param props.children The content of the section
 * @param props.large If sets, the section will be larger
 * @param props.title If sets, the title of the section
 * @param props.withBackground If sets, the section will have a white background
 * @param props.iconName The icon name
 * @param props.iconStatus The icon status
 * @returns The component
 */
export const LogoutSection = ({
	align = "stretch",
	backLink,
	children,
	iconName,
	iconStatus,
	large,
	title,
	withBackground,
}: LogoutSectionProps): JSX.Element => {
	const router = useRouter();

	return (
		<LogoutSectionContainer
			align={align}
			large={large}
			withBackground={withBackground}
		>
			{(iconName || title) && (
				<SectionHeader>
					{iconName && (
						<Icon
							color={
								iconStatus === "success"
									? theme.palette.green[3]
									: theme.palette.red[1]
							}
							name={iconName}
							size={48}
						/>
					)}

					{title && (
						<TitleContainer hasBackLink={!!backLink}>
							{backLink && (
								<Button
									href={backLink}
									onClick={() => void router.push(backLink)}
									startDecorator={
										<Icon
											name={IconName.chevronLeft}
											size={20}
										/>
									}
									variant={ButtonVariants.SECONDARY}
								/>
							)}
							<Title>{title}</Title>
						</TitleContainer>
					)}
				</SectionHeader>
			)}

			{children}
		</LogoutSectionContainer>
	);
};

const LogoutSectionContainer = styled("section")<{
	align: AlignItems;
	large?: boolean;
	withBackground?: boolean;
}>`
	grid-column: ${({ large }) => (large ? "4 / span 6" : "5 / span 4")};

	${setForTablet(`
		grid-column: 2 / span 6;
	`)}
	${setForMobile(`
		grid-column: span 4;
		min-width: initial;
	`)}

	min-width: var(--width-logout-section-min);

	display: flex;
	flex-direction: column;
	align-items: ${({ align }) => align};
	gap: 32px;

	border-radius: 8px;
	background: ${({ theme, withBackground }) =>
		withBackground && theme.palette.white.white};
	padding: ${({ withBackground }) => (withBackground ? "24px" : "0")};
	margin-bottom: ${({ withBackground }) =>
		withBackground ? "16px" : "24px"};
`;

const SectionHeader = styled("div")`
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 16px;
`;

const TitleContainer = styled("div")<{ hasBackLink: boolean }>`
	display: flex;
	align-self: ${({ hasBackLink }) => (hasBackLink ? "flex-start" : "center")};
	gap: 16px;

	text-align: ${({ hasBackLink }) => (hasBackLink ? "left" : "center")};
`;

const Title = styled("h2")`
	${textH1}
`;
