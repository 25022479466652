import { HttpRoute } from "@appti/core";

import {
	ApplicationVersionExtendedDto,
	ApplicationVersionQueryDto,
	ApplicationVersionQueryResultsDto,
} from "./dtos";
import { createGroupPermissionHttpDefinitions } from "./group-permission/group-permissions.http";

/**
 * Create routes definitions for `applicationVersion` features
 *
 * @param base of the HttpRoute builder
 * @returns route definitions
 */
export function createVersionHttpDefinitions<
	const BASE extends HttpRoute.BuilderAny,
>(base: BASE) {
	const pathById = base.addSegment({
		param: "versionId",
		type: "param",
		validation: "number",
	});

	return {
		children: {
			permissions: createGroupPermissionHttpDefinitions(
				pathById.addSegment("permissions"),
			),
		},
		findAndCount:
			base.get<
				(
					query: ApplicationVersionQueryDto,
				) => Promise<ApplicationVersionQueryResultsDto>
			>(),
		findById: pathById.get<() => Promise<ApplicationVersionExtendedDto>>(),
	} as const;
}
