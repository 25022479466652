import { css } from "@emotion/react";
import { useEffect, useState } from "react";

export const setForMobile = (style: string) => css`
	@media (max-width: 599px) {
		${style}
	}
`;

export const setForTablet = (style: string) => css`
	@media (max-width: 1079px) {
		${style}
	}
`;

/** Screen size for responsive needs */
export enum SIZE {
	DESKTOP = "DESKTOP",
	MOBILE = "MOBILE",
	TABLET = "TABLET",
}

/**
 * Hook that returns the current device size
 *
 * @returns The device size
 */
export const useDeviceSize = () => {
	const [deviceSize, setDeviceSize] = useState<SIZE>(SIZE.DESKTOP);
	const onResize = () => {
		const { innerWidth = 0 } = window;
		const {
			documentElement: { clientWidth },
		} = document;
		const width = Math.max(clientWidth, innerWidth);
		if (width < 1080 && 600 <= width) {
			setDeviceSize(SIZE.TABLET);
		} else if (width < 600) {
			setDeviceSize(SIZE.MOBILE);
		}
	};

	useEffect(() => {
		onResize();
		window.addEventListener("resize", onResize);
		return () => window.removeEventListener("resize", onResize);
	}, []);

	return {
		deviceSize,
	};
};
