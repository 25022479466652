import { styled } from "@mui/joy";
import { useMutation } from "@tanstack/react-query";
import { Form, Formik } from "formik";
import { useParams } from "next/navigation";
import { useTranslation } from "next-i18next";
import { useEffect, useState } from "react";
import { z } from "zod";
import { toFormikValidationSchema } from "zod-formik-adapter";
import { userUpdateDtoSchema } from "~/common/user/dtos";

import { ButtonMapping, ConfirmModalButton } from "./ConfirmModal";
import { LayoutModal, ModalProps } from "./LayoutModal";
import { useModal } from "./ModalProvider";
import { organizationUserRepository } from "../../../../services/api/repositories/organizationUserRepository";
import {
	Button,
	ButtonVariants,
} from "../../../ui-form/components/Button/Button";
import { FormElementWrapper } from "../../../ui-form/components/Formik/FormElementWrapper";
import { Input } from "../../../ui-form/components/Input";
import { Switch } from "../../../ui-form/components/Switch";
import { FormLayout } from "../FormLayout";

/** property detail of current user */
export interface UserProps {
	email?: string;
	enabled?: boolean;
	firstName?: string;
	id: number;
	isAdmin?: boolean;
	lastName?: string;
	password?: string;
}

/** Different state or function for modal */
export type StateProps = Pick<ModalProps, "onClose" | "open"> & {
	disabled: boolean;
	refreshUserList: () => void;
};

/** Props for handle state and user properties */
export interface AccountFormProps {
	state: StateProps;
	user: UserProps;
}

const DisableButtonContainer = styled("div")`
	margin-top: 20px;
`;

export const AccountFormModal = ({ state, user }: AccountFormProps) => {
	const { t } = useTranslation();
	const params = useParams<{ organizationId: string }>();

	const [isOpen, setIsOpen] = useState(true);
	const [isEnabled, setIsEnabled] = useState(user.enabled);
	const isFieldDeactivate = true;

	const initialValues: FormValues = {
		email: user.email || "",
		isAdmin: user.isAdmin || false,
		name: {
			first: user.firstName || "",
			last: user.lastName || "",
		},
	};

	const finalButtons = [
		ConfirmModalButton.BUTTON_NO,
		ConfirmModalButton.BUTTON_YES,
	];
	const modal = useModal();

	const { mutateAsync } = useMutation({
		...organizationUserRepository.updateUserStatus(
			user.id,
			Number(params.organizationId),
		),
		meta: {
			onSuccess: () => {
				state.refreshUserList();
			},
		},
	});

	useEffect(() => {
		setIsOpen(state.open || true);
	}, [state.open]);

	useEffect(() => {
		setIsEnabled(user.enabled);
	}, [user.enabled]);

	const actions: ModalProps["actions"] = _actionOnClose => (
		<>
			{finalButtons.map(btn => {
				const btnBase = ButtonMapping[btn];

				return (
					<Button
						{...btnBase}
						key={btn}
						label={btnBase.label && t(btnBase.label)}
						onClick={() => state.onClose?.()}
					/>
				);
			})}
		</>
	);

	const body = (
		<FormContainer data-testid="account-form-modal">
			<Formik<FormValues>
				initialValues={initialValues}
				onSubmit={() => {
					return;
				}}
				validationSchema={toFormikValidationSchema(validationSchema)}
			>
				{() => (
					<Form>
						<FormLayout>
							<FormElementWrapper
								disabled={isFieldDeactivate}
								label={t("entities.user.last-name")}
								names={["name.last"]}
							>
								<Input />
							</FormElementWrapper>
							<FormElementWrapper
								disabled={isFieldDeactivate}
								label={t("entities.user.first-name")}
								names={["name.first"]}
							>
								<Input />
							</FormElementWrapper>
							<FormElementWrapper
								disabled={isFieldDeactivate}
								label={t("entities.user.email")}
								names={["email"]}
							>
								<Input />
							</FormElementWrapper>

							<FormElementWrapper
								disabled={isFieldDeactivate}
								label={t("entities.user.password")}
								names={["password"]}
							>
								<Input
									disabled={isFieldDeactivate}
									placeholder={"************"}
									type="password"
								/>
							</FormElementWrapper>

							<FormElementWrapper<FormValues>
								disabled={isFieldDeactivate}
								names={["isAdmin"]}
								styles={{ marginTop: "12px" }}
							>
								<Switch label={t("entities.user.role-admin")} />
							</FormElementWrapper>
						</FormLayout>
						<DisableButtonContainer>
							{isEnabled ? (
								<Button
									label={t("pages.user-edit.form.deactivate")}
									onClick={() => {
										modal.openModal("confirm", {
											buttons: [
												ConfirmModalButton.BUTTON_NO,
												ConfirmModalButton.BUTTON_DELETE,
											],
											message: t(
												"pages.user-edit.modal.content",
											),
											onClick: async (
												btn,
											): Promise<void> => {
												if (
													btn ===
													ConfirmModalButton.BUTTON_DELETE
												) {
													await mutateAsync({
														enable: false,
														type: "de-enable",
													});
													setIsEnabled(false);
													state.refreshUserList();
												}
												modal.closeModal();
											},
											onClose: () => undefined,
											title: t(
												"pages.user-edit.modal.title",
											),
										});
									}}
									variant={ButtonVariants.DANGER_SECONDARY}
								/>
							) : (
								<Button
									label={t("pages.user-edit.form.activate")}
									onClick={() => {
										setIsEnabled(true);
										void mutateAsync({
											enable: true,
											type: "de-enable",
										});
										state.refreshUserList();
									}}
									variant={ButtonVariants.SECONDARY}
								/>
							)}
						</DisableButtonContainer>
					</Form>
				)}
			</Formik>
		</FormContainer>
	);

	const onCloseModal = () => {
		setIsOpen(false);
		state.onClose?.();
	};
	return (
		<LayoutModal
			actions={actions}
			body={body}
			onClose={onCloseModal}
			open={isOpen}
			title={t("pages.user-edit.title")}
		/>
	);
};

const FormContainer = styled("div")`
	display: flex;
	flex-direction: column;
	width: 100%;
`;

const validationSchema = userUpdateDtoSchema.extend({
	email: z.string(),
	isAdmin: z.boolean(),
});
type FormValues = z.infer<typeof validationSchema>;
