import * as z from "zod";

/** Validation schema for {@link Dto} */
export const schema = z.object({
	password: z.string().describe("New password given by user"),
	token: z.string().describe("The token given by server to reset password"),
});

/** DTO to update the user password */
export type Dto = z.infer<typeof schema>;
