import {
	CircularProgress,
	CircularProgressProps,
	circularProgressClasses,
	styled,
} from "@mui/joy";

/**
 * Spinner component
 *
 * @param props per CircularProgressProps from Joy
 * @returns JSX.Element
 */
export const Spinner = (props: CircularProgressProps): JSX.Element => (
	<SpinnerWrapper variant="soft" {...props} />
);

const SpinnerWrapper = styled(CircularProgress)`
	& .${circularProgressClasses.track} {
		stroke: ${({ theme }) => theme.vars.palette.grey[2]};
	},
	& .${circularProgressClasses.progress} {
		stroke: ${({ theme }) => theme.vars.palette.grey[4]};
	},
`;
