import { HttpRoute } from "@appti/core";

import {
	OrganizationUserCreateDto,
	OrganizationUserDto,
	OrganizationUserQueryDto,
	OrganizationUserQueryResultsDto,
} from "./dtos";
import { OrganizationUserUpdateDto } from "./dtos/organization-user.update.dto";

/**
 * Create routes definitions for `user` features (inside organization)
 *
 * @param base of the HttpRoute builder
 * @returns route definitions
 */
export function createUserHttpDefinitions<
	const BASE extends HttpRoute.BuilderAny,
>(base: BASE) {
	const byId = base.addSegment({
		param: "userId",
		type: "param",
		validation: "number",
	});

	return {
		create: base.post<
			(body: OrganizationUserCreateDto) => Promise<OrganizationUserDto>
		>(),
		findAndCount:
			base.get<
				(
					query: OrganizationUserQueryDto,
				) => Promise<OrganizationUserQueryResultsDto>
			>(),
		findById: byId.get<() => Promise<OrganizationUserDto>>(),
		update: byId.patch<
			(body: OrganizationUserUpdateDto) => Promise<void>
		>(),
	} as const satisfies HttpRoute.Definitions;
}
