import {
	APPLICATION_INSTANCE_STATUS as STATUS,
	ApplicationInstanceStatus,
} from "../instance.status";
import { ApplicationInstanceStep } from "../instance.step";

const statusesEntries = Object.entries(STATUS) as ReadonlyArray<
	[ApplicationInstanceStatus, (typeof STATUS)[ApplicationInstanceStatus]]
>;
/**
 * Gets the {@link ApplicationInstanceStatus} from {@link ApplicationInstanceStep}
 *
 * @param step to get the status from
 * @throws when the parameter is wrong
 * @returns the status
 */
export function getStatusFromStep(
	step: ApplicationInstanceStep,
): ApplicationInstanceStatus {
	// TODO: Move to a "utils" folder?
	const entry = statusesEntries.find(
		([_, { schema }]) => schema.safeParse(step).success,
	);

	if (!entry) {
		// Should not happen (except with bad cast)
		throw new Error("The step does not exist");
	}

	return entry[0];
}
