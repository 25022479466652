import * as z from "zod";

import { schemaDto } from "./content/content.dto";
import {
	applicationElementModelSchema,
	ApplicationElementModel,
} from "./element.model";

/** Property to replace for the DTO */
const REPLACE_PROP = "content" as const satisfies keyof ApplicationElementModel;

/**
 * Validation schema for {@link ApplicationElementDto}
 *
 * To not forget `Schemas.objectForJson` when using from JSON data
 */
export const applicationElementDtoSchema = applicationElementModelSchema
	.omit({ [REPLACE_PROP]: true })
	.extend({ [REPLACE_PROP]: z.lazy(() => schemaDto) });

/** {@link ApplicationElementModel element} with all its tree content (for a ReadOne) */
export type ApplicationElementDto = z.infer<typeof applicationElementDtoSchema>;
