import { styled } from "@mui/joy";
import Image from "next/image";
import { useParams } from "next/navigation";
import { useTranslation } from "next-i18next";

import LogoGeneric from "../../../../public/staticfront/images/logo-generic.png";
import { ENVIRONMENT } from "../../../environment";

type LogoSize = "small" | "medium";

interface LogoProps {
	size?: LogoSize;
}

export const Logo = ({ size = "small" }: LogoProps) => {
	const { t } = useTranslation();
	const params = useParams<{ organizationId: string }>();

	const organizationLogo = ENVIRONMENT.logos[+params?.organizationId];

	if (!organizationLogo) {
		return (
			<Image
				alt={t("common.logo.alt")}
				priority
				src={LogoGeneric}
				width={size === "small" ? "150" : "200"}
			/>
		);
	}

	return (
		<LogoContainer size={size}>
			{/* eslint-disable-next-line @next/next/no-img-element -- we use img because it's more adaptable to various layouts */}
			<img alt={t("common.logo.alt")} src={organizationLogo} />
		</LogoContainer>
	);
};

const LogoContainer = styled("div")<{ size: LogoSize }>`
	height: ${({ size }) => (size === "small" ? "50px" : "80px")};
	width: ${({ size }) => (size === "small" ? "150px" : "200px")};

	display: flex;
	align-items: center;
	justify-content: center;

	img {
		max-height: 100%;
		max-width: 100%;
	}
`;
