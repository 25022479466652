import {
	GroupQueryDto,
	GroupQueryResultsDto,
} from "~/common/organization/group/dtos";

import { apiEndpoints } from "../endpoints";
import { CustomUseQueryOptions } from "../reactQueryConfig";
import { FetchError } from "../utils/FetchError";

export const OrganizationGroupRepository = {
	findAndCount: (
		query: GroupQueryDto,
		orgId: number,
	): CustomUseQueryOptions<GroupQueryResultsDto, FetchError> => ({
		queryFn: () =>
			apiEndpoints.organizationGroup.findAndCount(query, orgId),
		queryKey: ["group"],
	}),
};
