import * as z from "zod";

/**
 * Validation schema for {@link UserRole}
 *
 * MODERATOR : Can update status
 * SECRETARY : Can update input values
 */
export const userRoleSchema = z.enum(["ADMIN", "MODERATOR", "SECRETARY"]);

/** List of known roles for an user */
export const USER_ROLES = userRoleSchema.options;

/**
 * Known roles, it can determines permissions
 * 	Ex.: user administration, application template modification, ...
 */
export type UserRole = z.infer<typeof userRoleSchema>;
