import { TFunction } from "i18next";
import {
	ApplicationInstanceStep,
	applicationInstanceStepSchema,
} from "~/common/application/instance";

import { stepMapping } from "../../../utils/commons/stepsTranslationMaps";
import { ToastVariants } from "../../ui-atoms/components/Toast";
import { showToast } from "../../ui-atoms/services/showToast";

/**
 * Function that displays a notification based based on the application new step
 *
 * @param newStep The application step we want to get the notification content for
 * @param t The translation function
 */
export function showUpdatedStepNotification(
	newStep: ApplicationInstanceStep,
	t: TFunction,
) {
	const title = t(
		"pages.application-details.notifications.change-step.success-title",
	);
	const newStepName = stepMapping(t)[newStep];
	let message = t(
		"pages.application-details.notifications.change-step.success-message-generic",
		{
			newStepName,
		},
	);

	switch (newStep) {
		case "services_validation":
			message = t(
				"pages.application-details.notifications.change-step.success-message-service-validation",
				{
					newStepName,
				},
			);
			break;
		case "invoice_preparation":
			message = t(
				"pages.application-details.notifications.change-step.success-message-service-validation",
				{
					newStepName,
				},
			);
			break;
		case "pending_client_validation":
			message = t(
				"pages.application-details.notifications.change-step.success-message-client-validation",
				{
					newStepName,
				},
			);
			break;
	}

	showToast({
		message,
		title,
		variant: ToastVariants.SUCCESS,
	});
}

/**
 * Function that returns a warning message based on the application old and new step
 *
 * @param oldStep The application old step
 * @param newStep The application new step
 * @param t The translation function
 * @returns The warning message
 */
export function getUpdatedStepWarning(
	oldStep: ApplicationInstanceStep | undefined,
	newStep: ApplicationInstanceStep | undefined,
	t: TFunction,
) {
	if (!oldStep || !newStep) {
		return null;
	}

	const orderedSteps = applicationInstanceStepSchema.options;
	const idxServiceValidation = orderedSteps.indexOf("services_validation");
	const idxInvoicePreparation = orderedSteps.indexOf("invoice_preparation");
	const idxOldStep = orderedSteps.indexOf(oldStep);
	const idxNewStep = orderedSteps.indexOf(newStep);

	if (
		idxOldStep < idxServiceValidation &&
		idxInvoicePreparation < idxNewStep
	) {
		return t(
			"pages.application-details.modal-update-status.warning-service-validation-and-prep",
		);
	}

	if (
		idxOldStep < idxServiceValidation &&
		idxServiceValidation < idxNewStep
	) {
		return t(
			"pages.application-details.modal-update-status.warning-service-validation",
		);
	}

	if (
		idxOldStep < idxInvoicePreparation &&
		idxInvoicePreparation < idxNewStep
	) {
		return t(
			"pages.application-details.modal-update-status.warning-invoice-prep",
		);
	}

	return null;
}
