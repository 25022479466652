import { styled } from "@mui/joy";
import Head from "next/head";
import { useTranslation } from "next-i18next";
import {
	createContext,
	Dispatch,
	ReactNode,
	SetStateAction,
	useState,
} from "react";

import { Logo } from "../components/Logo";
import { LogoutSection } from "../components/LogoutSection";
import { setForMobile, setForTablet } from "../styles/breakpoints";
import { textH3 } from "../styles/textStyles";

interface LayoutLoggedOutProps {
	children: ReactNode;
	logoTitle?: string;
	title: string;
}

export const TokenContext = createContext<{
	resetToken: string | null;
	setResetToken: Dispatch<SetStateAction<string>> | null;
}>({
	resetToken: null,
	setResetToken: null,
});

export const LayoutLogout = ({
	children,
	logoTitle,
	title,
}: LayoutLoggedOutProps): JSX.Element => {
	const { t } = useTranslation();
	const [resetToken, setResetToken] = useState("");

	const logoTitleFinal = t(logoTitle ?? "common.platform-title");

	return (
		<TokenContext.Provider
			value={{
				resetToken,
				setResetToken,
			}}
		>
			<Head>
				<title>{`${logoTitleFinal} - ${t(title)}`}</title>
			</Head>

			<BodyWrapper>
				<LogoutGridLayout>
					<LogoutSection>
						<HeaderContainer>
							<Logo size="medium" />
							<LogoTitle>{logoTitleFinal}</LogoTitle>
						</HeaderContainer>
					</LogoutSection>

					{children}
				</LogoutGridLayout>
			</BodyWrapper>
		</TokenContext.Provider>
	);
};

// Sets full screen display
const BodyWrapper = styled("div")`
	min-height: 100vh;

	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
`;

// Sets grid layout
const LogoutGridLayout = styled("div")`
	padding: 32px 48px;
	width: 100%;

	display: grid;
	grid-template-columns: repeat(12, 1fr);
	grid-column-gap: 24px;

	${setForTablet(`
		padding: 32px;
		grid-template-columns: repeat(8, 1fr);
	`)}
	${setForMobile(`
		padding: 32px 16px;
		grid-template-columns: repeat(4, 1fr);
		grid-column-gap: 16px;
	`)}

	z-index: var(--z-index-content);
`;

const LogoTitle = styled("h1")`
	${textH3}
`;

const HeaderContainer = styled("div")`
	display: flex;
	flex-direction: column;
	align-items: center;

	gap: 6px;
`;
