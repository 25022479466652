import {
	AuthLogin,
	AuthPasswordReset,
	AuthPasswordResetByEmail,
	AuthPasswordSet,
	AuthProfile,
	AuthRefresh,
	AuthSuccess,
} from "~/common/auth";

import { apiEndpoints } from "../endpoints";
import {
	CustomUseMutationOptions,
	CustomUseQueryOptions,
} from "../reactQueryConfig";
import { FetchError } from "../utils/FetchError";

export const authRepository = {
	emailReset: (): CustomUseMutationOptions<
		AuthPasswordResetByEmail.Dto,
		FetchError,
		AuthPasswordResetByEmail.Dto
	> => ({
		meta: {
			disableDefaultSuccessHandler: true,
		},
		mutationFn: body => apiEndpoints.auth.emailReset(body),
		mutationKey: [],
	}),
	getProfile: (): CustomUseQueryOptions<AuthProfile.Dto, FetchError> => ({
		queryFn: () => apiEndpoints.auth.getProfile(),
		queryKey: ["user", "expireOn", "issuedAt"],
	}),
	login: (): CustomUseMutationOptions<
		AuthSuccess.Dto,
		FetchError,
		AuthLogin.Dto
	> => ({
		meta: {
			disableDefaultSuccessHandler: true,
		},
		mutationFn: body => apiEndpoints.auth.login(body),
		mutationKey: [],
	}),
	logout: (): CustomUseMutationOptions<null, FetchError, void> => ({
		meta: {
			disableDefaultSuccessHandler: true,
		},
		mutationFn: () => apiEndpoints.auth.logout(),
		mutationKey: [],
	}),
	passwordSet: (): CustomUseMutationOptions<
		AuthPasswordSet.Dto,
		FetchError,
		AuthPasswordSet.Dto
	> => ({
		mutationFn: body => apiEndpoints.auth.passwordSet(body),
		mutationKey: [],
		onError: () => {
			// So that we don't display error notification
			return;
		},
	}),
	refresh: (): CustomUseMutationOptions<
		AuthSuccess.Dto,
		FetchError,
		AuthRefresh.Dto
	> => ({
		meta: {
			disableDefaultSuccessHandler: true,
		},
		mutationFn: body => apiEndpoints.auth.refresh(body),
		mutationKey: [],
	}),
	reset: (): CustomUseMutationOptions<
		AuthPasswordReset.Dto,
		FetchError,
		AuthPasswordReset.Dto
	> => ({
		meta: {
			disableDefaultSuccessHandler: true,
		},
		mutationFn: body => apiEndpoints.auth.reset(body),
		mutationKey: [],
	}),
};
