import { createQueryObjectSchema } from "@appti/core";
import * as z from "zod";

import { organizationUserDtoSchema } from "./organization-user.dto";

/** Validation schema for {@link OrganizationUserQueryDto} */
export const organizationUserQueryDtoSchema = createQueryObjectSchema(
	organizationUserDtoSchema,
	{ coerce: true, strict: true },
).strict();

/** DTO used to filter organization-users */
export type OrganizationUserQueryDto = z.infer<
	typeof organizationUserQueryDtoSchema
>;
