import { styled } from "@mui/joy";
import { ReactNode, useCallback } from "react";

import { Icon, IconName } from "./Icons/Icon";
import { XOR } from "../../../utils/types/XOR";
import { Button, ButtonVariants } from "../../ui-form/components/Button/Button";
import { text, textH3 } from "../../ui-layout/styles/textStyles";
import { theme } from "../../ui-layout/styles/theme";

/** The list of available toast variants */
export enum ToastVariants {
	ERROR = "ERROR",
	INFO = "INFO",
	SUCCESS = "SUCCESS",
}

export type ToastProps = XOR<{ message?: string }, { children?: ReactNode }> & {
	onClose?: () => void;
	title: string;
	variant: ToastVariants;
};

interface ToastifyProps {
	closeToast?: () => void;
}

export const Toast = ({
	children,
	closeToast,
	message,
	onClose,
	title,
	// Currently unused, it'as actually used in showToast
	variant,
}: ToastifyProps & ToastProps): JSX.Element => {
	const onCloseWrapper = useCallback(() => {
		if (onClose) {
			onClose();
		}
		if (closeToast) {
			closeToast();
		}
	}, [closeToast, onClose]);

	const ToastIcon = () => {
		switch (variant) {
			case ToastVariants.SUCCESS:
				return <Icon name={IconName.checkmarkOutline} size={24} />;
			case ToastVariants.ERROR:
				return <Icon name={IconName.error} size={24} />;
			default:
				return null;
		}
	};

	return (
		<ToastContainer variant={variant}>
			<ToastHeader>
				<ToastIcon />
				<ToastTitle>{title}</ToastTitle>

				{(onClose || closeToast) && (
					<Button
						onClick={onCloseWrapper}
						startDecorator={
							<Icon name={IconName.close} size={20} />
						}
						variant={ButtonVariants.TEXT}
					/>
				)}
			</ToastHeader>

			<ToastContent css={text}>{message ?? children}</ToastContent>
		</ToastContainer>
	);
};

type StyleDef = {
	[variant in ToastVariants]: {
		backgroundColor: string;
		borderColor: string;
		textColor: string;
	};
};

export const styles: StyleDef = {
	[ToastVariants.ERROR]: {
		backgroundColor: theme.palette.red[2],
		borderColor: theme.palette.red[1],
		textColor: theme.palette.red[3],
	},
	[ToastVariants.INFO]: {
		backgroundColor: theme.palette.blue[2],
		borderColor: theme.palette.blue.primary,
		textColor: theme.palette.blue.primary,
	},
	[ToastVariants.SUCCESS]: {
		backgroundColor: theme.palette.green[1],
		borderColor: theme.palette.green[3],
		textColor: theme.palette.green[2],
	},
};

const ToastContainer = styled("div")<{ variant: ToastVariants }>`
	position: relative;

	display: flex;
	flex-direction: column;
	gap: 9px;

	padding: 16px;

	border-radius: 8px;
	border: 1px solid ${props => styles[props.variant].borderColor};
	background: ${props => styles[props.variant].backgroundColor};
	color: ${props => styles[props.variant].textColor};

	z-index: var(--z-index-toast);
`;

const ToastHeader = styled("div")`
	display: flex;
	align-items: center;
	gap: 0.5rem;
`;

const ToastTitle = styled("h3")`
	margin-right: auto;
	${textH3}
`;

const ToastContent = styled("div")`
	display: flex;
	flex-direction: column;
	gap: 0.5rem;
	color: ${({ theme }) => theme.palette.grey.text};
`;
