import { styled } from "@mui/joy";
import { ReactNode } from "react";

import { useIsPublicRoute } from "../services/useIsPublicRoute";
import { setForMobile, setForTablet } from "../styles/breakpoints";

interface ContentWrapperProps {
	children: ReactNode;
}

export const ContentWrapper = ({
	children,
}: ContentWrapperProps): JSX.Element => {
	const { isPublicRoute } = useIsPublicRoute();
	return (
		<ContentWrapperStyled noHeader={isPublicRoute}>
			{children}
		</ContentWrapperStyled>
	);
};

const ContentWrapperStyled = styled("main")<{ noHeader: boolean }>`
	display: grid;
	grid-template-columns: repeat(12, 1fr);
	grid-column-gap: 24px;

	margin: 0 auto;
	width: 100vw;
	max-width: var(--width-content-max);

	padding: 48px;
	padding-top: ${({ noHeader }) =>
		noHeader ? "0" : "var(--height-content-start)"};

	${setForTablet(`
		padding: 0;
	`)}

	${setForMobile(`
		padding: 0;
	`)}

	z-index: var(--z-index-content);
`;
