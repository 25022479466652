import * as z from "zod";

import { userModelSchema } from "../user.model";

/** Validation schema for {@link UserUpdateDto} */
export const userUpdateDtoSchema = userModelSchema
	.pick({})
	.extend({ name: userModelSchema.shape.name.partial() })
	.partial()
	.strict();

/** Dto to update an user */
export type UserUpdateDto = z.infer<typeof userUpdateDtoSchema>;
