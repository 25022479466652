import { css } from "@emotion/react";
import { styled } from "@mui/joy";

export const textH1 = css`
	font-size: 24px;
	font-weight: 600;
	line-height: normal;
`;
export const textH2 = css`
	font-size: 20px;
	font-weight: 600;
	line-height: normal;
`;
export const textH3 = css`
	font-size: 18px;
	font-weight: 600;
	line-height: normal;
`;
export const text = css`
	font-size: 16px;
	font-weight: 500;
	line-height: normal;
`;
export const textSmall = css`
	font-size: 14px;
	font-weight: 400;
	line-height: normal;
`;
export const textSmallMedium = css`
	font-size: 14px;
	font-weight: 500;
	line-height: normal;
`;
export const textLabelBold = css`
	font-size: 14px;
	font-weight: 600;
	line-height: normal;
`;
export const textLabelSmall = css`
	font-size: 12px;
	font-weight: 500;
	line-height: normal;
`;
export const textLabelTag = css`
	font-size: 11px;
	font-weight: 500;
	line-height: normal;
`;
export const ParagraphCenter = styled("p")`
	${text}
	text-align: center;
`;
