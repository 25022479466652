import { Model } from "@appti/core";
import * as z from "zod";

import { organizationModelSchema } from "../organization.model";

/**
 * A placeholder group ID to identify the Commune "group".
 * It's used in the user creation process, where we can select "Commune" as a group upon user creation,
 * but in reality there is no group attached to it.
 */
export const COMMUNE_NO_GROUP_ID = 0;

/** Validation schema for {@link GroupModel} */
export const groupModelSchema = Model.schema.extend({
	disabledAt: z
		.date({ description: "The date when this entity is disabled" })
		.nullable()
		.readonly(),
	// For now (at least), a group is only linked to a single Organization
	fkOrganization: organizationModelSchema.shape._id.describe(
		"The organization this group is part of",
	),

	name: z.string().min(3).describe("Name of the group"),
});

/** A group is a set of users ("service d'une commune") */
export type GroupModel = z.infer<typeof groupModelSchema>;
