import {
	Dropdown,
	Menu,
	MenuButton,
	MenuItem,
	Theme,
	menuItemClasses,
} from "@mui/joy";
import Link from "next/link";
import { ReactNode } from "react";

import { Icon, IconName } from "./Icons/Icon";
import { XOR } from "../../../utils/types/XOR";
import { theme } from "../../ui-layout/styles/theme";

/** Menu list variants */
export enum MenuListVariant {
	DEFAULT = "DEFAULT",
	SELECT = "SELECT",
}

/**
 * The definition of a menu item
 *
 * @interface menuItemDefinition
 * @property {UrlObject} href The URL to navigate to when the menu item is clicked
 * @property {Function} onClick The function to call when the menu item is clicked
 * @property {IconName} [icon] The icon to display next to the menu item
 * @property {string} label The text to display in the menu item
 */
export type menuItemDefinition = XOR<
	{ href: string },
	{ onClick: () => Promise<void> | void }
> & {
	icon?: IconName;
	id: number | string;
	label: ReactNode;
};

interface MenuListProps {
	children: React.ReactNode;
	menuItems: menuItemDefinition[];
	variant?: MenuListVariant;
}

export const MenuList = ({
	children,
	menuItems,
	variant = MenuListVariant.DEFAULT,
}: MenuListProps) => {
	const menuItemsComponents = menuItems.map(menu => {
		const ButtonComp = (
			<MenuItem
				key={menu.id}
				href={menu.href}
				onClick={
					menu.onClick &&
					(() => {
						void menu.onClick();
					})
				}
				sx={theme => getMenuItemStyle(theme, variant)}
			>
				{menu.icon && <Icon name={menu.icon} />}
				{menu.label}
			</MenuItem>
		);

		if (menu.href) {
			return (
				<Link key={menu.id} href={menu.href} passHref>
					{ButtonComp}
				</Link>
			);
		}

		return ButtonComp;
	});

	return (
		<Dropdown data-testid="dropdown-menu-list">
			<MenuButton
				endDecorator={<Icon name={IconName.chevronDown} />}
				sx={theme => getMenuButtonStyle(theme, variant)}
			>
				{children}
			</MenuButton>
			<Menu
				placement="bottom-start"
				sx={theme => getMenuStyle(theme, variant)}
			>
				{menuItemsComponents}
			</Menu>
		</Dropdown>
	);
};

type StyleDef = {
	[variant in MenuListVariant]: {
		border: string;
		buttonBackground: string;
		buttonColor: string;
		fontWeight: number;
		menuItemBorderBottom: string;
		menuItemBorderRadius: string;
		menuItemHeight: string;
		paddingMenu: string;
	};
};

const styles: StyleDef = {
	[MenuListVariant.DEFAULT]: {
		border: "none",
		buttonBackground: "none",
		buttonColor: theme.vars.palette.grey[4],
		fontWeight: 500,
		menuItemBorderBottom: "none",
		menuItemBorderRadius: "4px",
		menuItemHeight: "40px",
		paddingMenu: "8px",
	},
	[MenuListVariant.SELECT]: {
		border: `1px solid ${theme.vars.palette.grey[2]}`,
		buttonBackground: theme.vars.palette.white.white,
		buttonColor: theme.vars.palette.grey.text,
		fontWeight: 400,
		menuItemBorderBottom: `1px solid ${theme.vars.palette.grey[1]}`,
		menuItemBorderRadius: "0",
		menuItemHeight: "32px",
		paddingMenu: "0",
	},
};

function getMenuButtonStyle(theme: Theme, variant: MenuListVariant) {
	return {
		background: styles[variant].buttonBackground,
		border: styles[variant].border,
		borderRadius: "4px",
		color: styles[variant].buttonColor,
		fontWeight: styles[variant].fontWeight,
		padding: "10px 12px",
		width: "fit-content",

		"&:hover": {
			background: styles[variant].buttonBackground,
		},
	};
}

function getMenuStyle(
	theme: Theme,
	variant: MenuListVariant,
): Record<string, unknown> {
	return {
		border: `1px solid ${theme.vars.palette.grey[2]}`,
		borderRadius: "4px",
		boxShadow: "0px 0px 16px 0px rgba(0, 0, 0, 0.10)",
		padding: styles[variant].paddingMenu,
	};
}

function getMenuItemStyle(theme: Theme, variant: MenuListVariant) {
	return {
		borderBottom: styles[variant].menuItemBorderBottom,
		borderRadius: styles[variant].menuItemBorderRadius,

		color: theme.vars.palette.grey[4],
		fontSize: "14px",
		fontWeight: styles[variant].fontWeight,
		height: styles[variant].menuItemHeight,

		[`&.${menuItemClasses.root}:hover`]: {
			background: theme.vars.palette.grey[1],
			color: theme.vars.palette.grey[4],
		},
	};
}
