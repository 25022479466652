import { APPLICATION_HTTP_CONFIG } from "~/common/application";
import {
	ApplicationValueDto,
	ApplicationValueExtendedDto,
} from "~/common/application/dtos/value.dto";
import {
	ApplicationInstanceCountedByStatuses,
	ApplicationInstanceCountedByStatusesQuery,
	ApplicationInstanceCreateGroupCheckDto,
	ApplicationInstanceDto,
	ApplicationInstanceQueryDto,
	ApplicationInstanceQueryResultsDto,
	ApplicationInstanceUpdateInputDto,
	ApplicationInstanceUpdateStatusDto,
	WithUpdateInputError,
} from "~/common/application/instance/dtos";
import { ApplicationInstanceCreateDto } from "~/common/application/instance/dtos/instance.create.dto";
import { ApplicationGroupCheckDto } from "~/common/application/instance/group-check/dtos";
import { GenericOrganizerEmailDtoQuery } from "~/common/auth";

import { RequestMethod, fetchRequest } from "../utils/utils";

const { instances: route } = APPLICATION_HTTP_CONFIG.children;

export const apiApplicationInstanceEndpoints = {
	countByStatus: (
		orgId: number,
		query: ApplicationInstanceCountedByStatusesQuery = {},
	): Promise<ApplicationInstanceCountedByStatuses> =>
		fetchRequest({
			endpoint: route.countByStatuses.path({ orgId }),
			method: RequestMethod.GET,
			query,
		}),
	create: (
		orgId: number,
		body: ApplicationInstanceCreateDto,
	): Promise<ApplicationInstanceDto> =>
		fetchRequest({
			body,
			endpoint: route.create.path({ orgId }),
			method: RequestMethod.POST,
		}),
	findAndCount: (
		orgId: number,
		query: ApplicationInstanceQueryDto,
	): Promise<ApplicationInstanceQueryResultsDto> =>
		fetchRequest({
			endpoint: route.findAndCount.path({ orgId }),
			method: RequestMethod.GET,
			query,
		}),
	findOne: (
		orgId: number,
		instanceId: ApplicationInstanceDto["_id"],
		query: GenericOrganizerEmailDtoQuery = {},
	): Promise<ApplicationInstanceDto> =>
		fetchRequest({
			endpoint: route.findOne.path({
				instanceId,
				orgId,
			}),
			method: RequestMethod.GET,
			query,
		}),
	getInputValues: (
		orgId: number,
		instanceId: ApplicationInstanceDto["_id"],
		query: GenericOrganizerEmailDtoQuery = {},
	): Promise<ApplicationValueExtendedDto[]> =>
		fetchRequest({
			endpoint: route.getInputValues.path({
				instanceId,
				orgId,
			}),
			method: RequestMethod.GET,
			query,
		}),
	groupChecks: {
		create: (
			orgId: number,
			instanceId: ApplicationInstanceDto["_id"],
			body: ApplicationInstanceCreateGroupCheckDto,
		): Promise<ApplicationGroupCheckDto | undefined> =>
			fetchRequest({
				body,
				endpoint: route.children.groupChecks.create.path({
					instanceId,
					orgId,
				}),
				method: RequestMethod.POST,
			}),
		findAndCount: (
			orgId: number,
			instanceId: ApplicationInstanceDto["_id"],
		): Promise<ApplicationGroupCheckDto[]> =>
			fetchRequest({
				endpoint: route.children.groupChecks.findAndCount.path({
					instanceId,
					orgId,
				}),
				method: RequestMethod.GET,
			}),
	},
	updateInput: (
		orgId: number,
		instanceId: number,
		body: ApplicationInstanceUpdateInputDto[],
		query: GenericOrganizerEmailDtoQuery = {},
	): Promise<WithUpdateInputError<ApplicationValueDto>> =>
		fetchRequest({
			body,
			endpoint: route.updateInput.path({ instanceId, orgId }),
			method: RequestMethod.PATCH,
			query,
		}),
	updateStatus: (
		orgId: number,
		instanceId: ApplicationInstanceDto["_id"],
		body: ApplicationInstanceUpdateStatusDto,
		query: GenericOrganizerEmailDtoQuery = {},
	): Promise<ApplicationInstanceDto> =>
		fetchRequest({
			body,
			endpoint: route.updateStatus.path({
				instanceId,
				orgId,
			}),
			method: RequestMethod.PATCH,
			query,
		}),
	validate: (orgId: number, instanceId: number): Promise<void> =>
		fetchRequest({
			endpoint: route.validate.path({ instanceId, orgId }),
			method: RequestMethod.POST,
		}),
};
