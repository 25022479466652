import * as z from "zod";

import { userUpdateDtoSchema } from "../../../user/dtos";

/** Validation schema for {@link OrganizationUserUpdateDto} */
export const organizationUserUpdateDtoSchema = z.discriminatedUnion("type", [
	z.object({ data: userUpdateDtoSchema, type: z.literal("regular") }),
	// To disabled/enable an user
	z.object({ enable: z.boolean(), type: z.literal("de-enable") }),
]);

export type OrganizationUserUpdateDto = z.infer<
	typeof organizationUserUpdateDtoSchema
>;
