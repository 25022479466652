import * as z from "zod";

import * as Options from "./options";
import { DISCRIMINATION_KEY } from "./options/base";

/** Validation schema for {@link Dto} */
export const schemaDto = z.discriminatedUnion(DISCRIMINATION_KEY, [
	Options.Input.dtoSchema,
	Options.Paragraph.dtoSchema,
	Options.Section.dtoSchema,
	Options.Supply.dtoSchema,
]);

/** Specification of an element with all its tree content */
export type Dto = z.infer<typeof schemaDto>;
