import {
	Add,
	Calendar,
	Checkmark,
	CheckmarkOutline,
	ChevronDown,
	ChevronLeft,
	ChevronRight,
	CloseLarge,
	DocumentBlank,
	Email,
	Error,
	Information,
	Logout,
	MisuseOutline,
	ResultDraft,
	Search,
	Send,
	Settings,
	TaskSettings,
	TrashCan,
	UpdateNow,
	User,
	UserAvatarFilled,
	UserMultiple,
	UserSettings,
	View,
	ViewOff,
	Workspace,
} from "@carbon/icons-react";
import { styled } from "@mui/joy";
import { cloneElement, FC, SVGProps } from "react";

import Autosave from "../../../../assets/images/autosave.svg";
import Sort from "../../../../assets/images/chevron_sort.svg";
import SortDown from "../../../../assets/images/chevron_sort_down.svg";
import SortUp from "../../../../assets/images/chevron_sort_up.svg";
import Edit from "../../../../assets/images/edit.svg";

enum IconTypes {
	IBM = "IBM",
	SVG = "SVG",
}

type SvgComponent = FC<SVGProps<SVGSVGElement>>;

interface IconSpecIBM {
	Component: JSX.Element;
	type: IconTypes.IBM;
}

interface IconSpecSVG {
	Component: SvgComponent;
	type: IconTypes.SVG;
}

/* eslint-disable @typescript-eslint/naming-convention -- exception is done for icons*/
/** List of icons */
export enum IconName {
	add = "add",
	autosave = "autosave",
	avatar = "avatar",
	calendar = "calendar",
	checkmark = "checkmark",
	checkmarkOutline = "checkmarkOutline",
	chevronDown = "chevronDown",
	chevronLeft = "chevronLeft",
	chevronRight = "chevronRight",
	close = "close",
	document = "document",
	edit = "edit",

	email = "email",
	error = "error",
	information = "information",
	logout = "logout",
	misuseOutline = "misuseOutline",
	resultDraft = "resultDraft",
	search = "search",
	send = "send",
	settings = "settings",
	sort = "sort",
	sortDown = "sortDown",
	sortUp = "sortUp",
	taskSettings = "taskSettings",
	trash = "trash",
	updateNow = "updateNow",
	user = "user",
	userMultiple = "userMultiple",
	userSettings = "userSettings",
	view = "view",
	viewOff = "viewOff",
	workspace = "workspace",
}

/* eslint-enable @typescript-eslint/naming-convention */

/** Map icons to their visual asset */
const SvgMap: Record<string, IconSpecIBM | IconSpecSVG> = {
	[IconName.workspace]: {
		Component: <Workspace />,
		type: IconTypes.IBM,
	},
	[IconName.taskSettings]: {
		Component: <TaskSettings />,
		type: IconTypes.IBM,
	},
	[IconName.userMultiple]: {
		Component: <UserMultiple />,
		type: IconTypes.IBM,
	},
	[IconName.chevronDown]: {
		Component: <ChevronDown />,
		type: IconTypes.IBM,
	},
	[IconName.userSettings]: {
		Component: <UserSettings />,
		type: IconTypes.IBM,
	},
	[IconName.logout]: {
		Component: <Logout />,
		type: IconTypes.IBM,
	},
	[IconName.avatar]: {
		Component: <UserAvatarFilled />,
		type: IconTypes.IBM,
	},
	[IconName.user]: {
		Component: <User />,
		type: IconTypes.IBM,
	},
	[IconName.add]: {
		Component: <Add />,
		type: IconTypes.IBM,
	},
	[IconName.trash]: {
		Component: <TrashCan />,
		type: IconTypes.IBM,
	},
	[IconName.settings]: {
		Component: <Settings />,
		type: IconTypes.IBM,
	},
	[IconName.close]: {
		Component: <CloseLarge />,
		type: IconTypes.IBM,
	},
	[IconName.checkmark]: {
		Component: <Checkmark />,
		type: IconTypes.IBM,
	},
	[IconName.checkmarkOutline]: {
		Component: <CheckmarkOutline />,
		type: IconTypes.IBM,
	},
	[IconName.error]: {
		Component: <Error />,
		type: IconTypes.IBM,
	},
	[IconName.view]: {
		Component: <View />,
		type: IconTypes.IBM,
	},
	[IconName.viewOff]: {
		Component: <ViewOff />,
		type: IconTypes.IBM,
	},
	[IconName.chevronRight]: {
		Component: <ChevronRight />,
		type: IconTypes.IBM,
	},
	[IconName.calendar]: {
		Component: <Calendar />,
		type: IconTypes.IBM,
	},
	[IconName.document]: {
		Component: <DocumentBlank />,
		type: IconTypes.IBM,
	},
	[IconName.chevronLeft]: {
		Component: <ChevronLeft />,
		type: IconTypes.IBM,
	},
	[IconName.updateNow]: {
		Component: <UpdateNow />,
		type: IconTypes.IBM,
	},
	[IconName.resultDraft]: {
		Component: <ResultDraft />,
		type: IconTypes.IBM,
	},
	[IconName.information]: {
		Component: <Information />,
		type: IconTypes.IBM,
	},
	[IconName.misuseOutline]: {
		Component: <MisuseOutline />,
		type: IconTypes.IBM,
	},
	[IconName.search]: {
		Component: <Search />,
		type: IconTypes.IBM,
	},
	[IconName.send]: {
		Component: <Send />,
		type: IconTypes.IBM,
	},
	[IconName.email]: {
		Component: <Email />,
		type: IconTypes.IBM,
	},
	[IconName.sort]: {
		Component: Sort as SvgComponent,
		type: IconTypes.SVG,
	},
	[IconName.sortDown]: {
		Component: SortDown as SvgComponent,
		type: IconTypes.SVG,
	},
	[IconName.sortUp]: {
		Component: SortUp as SvgComponent,
		type: IconTypes.SVG,
	},
	[IconName.autosave]: {
		Component: Autosave as SvgComponent,
		type: IconTypes.SVG,
	},
	[IconName.edit]: {
		Component: Edit as SvgComponent,
		type: IconTypes.SVG,
	},
};

interface IconProps {
	color?: string;
	name: IconName;
	size?: number;
}

/**
 * Icon component
 *
 * @param param0 Props
 * @param param0.color Icon color (hex, rgb, rgba, hsl, hsla, etc.)
 * @param param0.name Icon name (from IconName enum)
 * @param param0.size Icon size (in px)
 * @returns {JSX.Element} Icon component
 */
export const Icon = ({
	color = "inherit",
	name,
	size = 16,
}: IconProps): JSX.Element => {
	const { Component, type } = SvgMap[name];

	if (type === IconTypes.IBM) {
		return (
			<IconWrapperIBM height={`${size}px`}>
				{cloneElement(Component, { color, size })}
			</IconWrapperIBM>
		);
	}

	return (
		<IconWrapperSVG color={color} height={`${size}px`}>
			<Component />
		</IconWrapperSVG>
	);
};

const IconWrapperSVG = styled("div")<{ color: string; height: string }>`
	height: ${props => props.height};
	fill: ${props => props.color};

	svg {
		height: 100%;
	}
`;

const IconWrapperIBM = styled("div")<{ height: string }>`
	height: ${props => props.height};
`;
