import * as z from "zod";

import { schema as loginSchema } from "./auth.login.dto";

const password = loginSchema.shape.password;

/** Validation schema for {@link Dto} */
export const schema = z.object({
	passwordNew: password.describe("The new password to set."),
	passwordOld: password.describe("The current active password to replace."),
});

/** DTO when a (authenticated) user updates his password */
export type Dto = z.infer<typeof schema>;
