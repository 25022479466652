import { createQueryObjectSchema } from "@appti/core";
import * as z from "zod";

import { organizationDtoSchema } from "./organization.dto";

/** Validation schema for {@link OrganizationQueryDto} */
export const organizationQueryDtoSchema = createQueryObjectSchema(
	organizationDtoSchema,
	{ coerce: true, strict: true },
);

export type OrganizationQueryDto = z.infer<typeof organizationQueryDtoSchema>;
