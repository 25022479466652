import * as z from "zod";

import { createSchema } from "./base";
import { applicationProvisionModelSchema } from "../../provision";

/** The type of this input */
export const TYPE = "supply";

/** Validation schema for {@link Model} */
export const schema = createSchema(TYPE).extend({
	displaySubTotal: z
		.boolean()
		.default(true)
		.describe("Show the total of this element"),
	displayTotal: z
		.boolean()
		.default(false)
		.describe(
			"Show the total of the document, at the position at this element [NOT USED FOR NOW]",
		),
	title: z.string().describe("Title of the section [NOT USED FOR NOW]"),

	// Relation: "lines" of single provision item
});

/**
 * Application element that have supplies/provisions.
 * It contains, via relations, "provision" items
 */
export type Model = z.infer<typeof schema>;

/** Validation schema for {@link Dto} */
export const dtoSchema = schema.extend({
	provisions: z.array(applicationProvisionModelSchema),
});

/** Dto for {@link Model} with its provisions lines */
export type Dto = z.infer<typeof dtoSchema>;
