import { styled } from "@mui/joy";

export const FormLayout = styled("div")`
	width: 100%;

	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 24px;
`;
