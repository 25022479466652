import * as z from "zod";

import { userModelSchema } from "../../../user/user.model";

/** Validation schema for {@link OrganizationUserCreateDto} */
export const organizationUserCreateDtoSchema = userModelSchema
	.pick({ email: true, name: true })
	.extend({
		groupId: z
			.number()
			.nullable()
			.describe("Group to set the user at creation"),
		isAdmin: z.boolean().describe("Is the user an admin"),
	})
	.strict();

/** Dto to update an user by organization */
export type OrganizationUserCreateDto = z.infer<
	typeof organizationUserCreateDtoSchema
>;
