import { Model } from "@appti/core";
import * as z from "zod";

/**
 * The name of a user.
 * The first name is mandatory, last name can be an empty string.
 */
export const userNameModelSchema = z.object(
	{
		first: z.string().min(3).describe("First name"),
		last: z.string().describe("Last name (= family name)"),
	},
	{ description: "Descriptive name object" },
);
export type UserNameModel = z.infer<typeof userNameModelSchema>;

/** Schema for a user */
export const userModelSchema = Model.schema.extend({
	disabledAt: z
		.date()
		.nullable()
		.describe("The date were user was disable")
		.readonly(),
	email: z.string().describe("Email (and identifier) of a user").email(),
	lastLoginAt: z
		.date()
		.nullable()
		.describe("The date of the last login")
		.readonly(),
	name: userNameModelSchema,
});
export type UserModel = z.infer<typeof userModelSchema>;
