import { Button as ButtonJoy, Theme, buttonClasses } from "@mui/joy";
import { Route } from "next";
import Link from "next/link";
import { ReactNode } from "react";
import { UrlObject } from "url";

import { Spinner } from "../../../ui-atoms/components/Spinner";

/** The different button variants */
export enum ButtonVariants {
	DANGER = "DANGER",
	DANGER_SECONDARY = "DANGER_SECONDARY",
	PRIMARY = "PRIMARY",
	SECONDARY = "SECONDARY",
	TEXT = "TEXT",
}

/** Button props */
export interface ButtonProps {
	disabled?: boolean;
	endDecorator?: ReactNode;
	href?: UrlObject | string;
	isLoading?: boolean;
	label?: string;
	onClick?: () => void;
	startDecorator?: ReactNode;
	type?: "button" | "submit";
	variant?: ButtonVariants;
}

/**
 * Button component
 *
 * @param param0 Props
 * @param param0.disabled Whether the button is disabled
 * @param param0.endDecorator The decorator to display at the end of the button
 * @param param0.href The URL to navigate to when the button is clicked
 * @param param0.isLoading Whether the button is loading
 * @param param0.label The text to display in the button
 * @param param0.onClick The function to call when the button is clicked
 * @param param0.startDecorator The decorator to display at the start of the button
 * @param param0.type The type of the button
 * @param param0.variant The variant of the button
 * @returns JSX.Element
 */
export const Button = ({
	disabled,
	href,
	isLoading,
	label,
	startDecorator,
	type = "button",
	variant = ButtonVariants.PRIMARY,
	...rest
}: ButtonProps): JSX.Element => {
	const iconOnly = !!startDecorator && !label;

	const ButtonComp = (
		<ButtonJoy
			disabled={isLoading || disabled}
			startDecorator={isLoading ? <Spinner /> : startDecorator}
			type={type}
			{...rest}
			sx={theme => getButtonStyle(theme, variant, iconOnly)}
		>
			{label}
		</ButtonJoy>
	);

	if (href) {
		return (
			<Link href={href as Route} passHref>
				{ButtonComp}
			</Link>
		);
	}

	return ButtonComp;
};

type StyledVariant = Record<string, number | string | object>;

function getButtonStyle(
	theme: Theme,
	variant: ButtonVariants,
	iconOnly: boolean,
) {
	const styleVariants: { [key in ButtonVariants]: StyledVariant } = {
		[ButtonVariants.PRIMARY]: {
			"&:hover": { background: theme.vars.palette.blue[3] },
			background: theme.vars.palette.blue.primary,
			color: theme.vars.palette.white.white,
			height: "40px",
			width: iconOnly ? "40px" : "fit-content",

			[`&.${buttonClasses.disabled}`]: {
				background: theme.vars.palette.grey[3],
				color: theme.vars.palette.white.white,
				cursor: "not-allowed",
				pointerEvents: "all !important",
			},
		},
		[ButtonVariants.SECONDARY]: {
			"&:hover": { background: theme.vars.palette.grey[2] },
			background: theme.vars.palette.white.white,
			border: `1px solid ${theme.vars.palette.grey[2]}`,
			color: theme.vars.palette.grey[4],
			height: "40px",
			width: iconOnly ? "40px" : "fit-content",

			[`&.${buttonClasses.disabled}`]: {
				background: theme.vars.palette.white.white,
				color: theme.vars.palette.grey[3],
				cursor: "not-allowed",
				pointerEvents: "all !important",
			},
		},
		[ButtonVariants.TEXT]: {
			"&:hover": { background: "none" },
			background: "none",
			color: theme.vars.palette.grey[4],
			height: "fit-content",
			minHeight: "auto",
			padding: 0,
			width: "fit-content",

			[`&.${buttonClasses.disabled}`]: {
				background: "none",
				color: theme.vars.palette.grey[3],
				cursor: "not-allowed",
				pointerEvents: "all !important",
			},
		},
		[ButtonVariants.DANGER]: {
			"&:hover": { background: theme.vars.palette.red[1] },
			background: theme.vars.palette.red[3],
			color: theme.vars.palette.white.white,
			height: "40px",
			width: iconOnly ? "40px" : "fit-content",

			[`&.${buttonClasses.disabled}`]: {
				background: theme.vars.palette.red[3],
				color: theme.vars.palette.white.white,
				cursor: "not-allowed",
				pointerEvents: "all !important",
			},
		},
		[ButtonVariants.DANGER_SECONDARY]: {
			"&:hover": { background: theme.vars.palette.red[2] },
			background: theme.vars.palette.white.white,
			border: `1px solid ${theme.vars.palette.red[2]}`,
			color: theme.vars.palette.red[3],
			fontWeight: "400",
			height: "40px",
			width: iconOnly ? "40px" : "fit-content",

			[`&.${buttonClasses.disabled}`]: {
				background: theme.vars.palette.white.white,
				color: theme.vars.palette.red[3],
				cursor: "not-allowed",
				pointerEvents: "all !important",
			},
		},
	};

	// Return base style + variant style
	return {
		borderRadius: "8px",
		fontSize: "16px",
		fontWeight: 500,
		padding: "0 16px",

		[`& .${buttonClasses.startDecorator}`]: {
			marginRight: iconOnly ? "0px" : "8px",
		},

		...styleVariants[variant],
	};
}
