import { Form } from "formik";
import { useTranslation } from "next-i18next";
import * as z from "zod";
import { COMMUNE_NO_GROUP_ID } from "~/common/organization/group";
import { GroupDto } from "~/common/organization/group/dtos";
import { organizationUserCreateDtoSchema } from "~/common/organization/user/dtos";

import { FormElementWrapper } from "../../ui-form/components/Formik/FormElementWrapper";
import { Input } from "../../ui-form/components/Input";
import { Select, SelectOption } from "../../ui-form/components/Select";
import { Switch } from "../../ui-form/components/Switch";
import { FormLayout } from "../../ui-layout/components/FormLayout";

interface AddUserFormProps {
	groups: readonly GroupDto[];
}

export const AddUserForm = (props: AddUserFormProps) => {
	const { t } = useTranslation();

	const groupOptions = props.groups.map<SelectOption>(({ _id, name }) => ({
		label: `${t("entities.user.utility-service")} ${name}`,
		value: _id,
	}));
	// We manually insert the "Commune" option in the dropdown, which won't
	// create any group for the user
	groupOptions.unshift({
		label: t("pages.users.add-user.select-option-commune"),
		value: COMMUNE_NO_GROUP_ID as number,
	});

	return (
		<Form data-testid="add-user-form">
			<FormLayout>
				<FormElementWrapper<FormValues>
					label={t("entities.user.last-name")}
					names={["name.last"]}
					required
				>
					<Input />
				</FormElementWrapper>

				<FormElementWrapper<FormValues>
					label={t("entities.user.first-name")}
					names={["name.first"]}
					required
				>
					<Input />
				</FormElementWrapper>

				<FormElementWrapper<FormValues>
					label={t("entities.user.email")}
					names={["email"]}
					required
				>
					<Input />
				</FormElementWrapper>
				<FormElementWrapper<FormValues>
					label={t("entities.user.utility")}
					names={["groupId"]}
					required
				>
					<Select options={groupOptions} />
				</FormElementWrapper>

				<FormElementWrapper<FormValues> names={["isAdmin"]}>
					<Switch label={t("entities.user.admin")} />
				</FormElementWrapper>
			</FormLayout>
		</Form>
	);
};

const validationSchema = organizationUserCreateDtoSchema;
type FormValues = z.infer<typeof validationSchema>;

export { validationSchema as addUserFormValuesSchema };
export type { FormValues as AddUserFormValues };
