import * as z from "zod";

import { createSchema } from "./base";

/** The type of this input */
export const TYPE = "paragraph";

/** Validation schema for {@link Model} */
export const schema = createSchema(TYPE).extend({
	text: z.string().describe("Text of the paragraph"),
});

/** Application element that is a paragraph */
export type Model = z.infer<typeof schema>;

// Identity between Model and DTO
export type { Model as Dto };
export { schema as dtoSchema };
