import { createQueryResultsSchema } from "@appti/core";
import * as z from "zod";

import { organizationDtoSchema } from "./organization.dto";

/** Validation schema for {@link OrganizationQueryResultsDto} */
export const organizationQueryResultsDtoSchema = createQueryResultsSchema(
	organizationDtoSchema,
);

export type OrganizationQueryResultsDto = z.infer<
	typeof organizationQueryResultsDtoSchema
>;
