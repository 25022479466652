import {
	createContext,
	ReactNode,
	useCallback,
	useContext,
	useMemo,
	useState,
	JSX,
} from "react";

import { ModalName, ModalData, ModalParams, ShowModal } from "./modalsList";

interface ModalContextValue {
	closeModal: () => void;
	openModal: <T extends ModalName>(
		modalName: T,
		params: ModalParams<T>,
	) => void;
}

const ModalContext = createContext<ModalContextValue | null>(null);

export const ModalProvider = ({
	children,
}: {
	children: ReactNode;
}): JSX.Element => {
	const [modals, setModals] = useState<ModalData[]>([]);
	const openModal = useCallback(
		<T extends ModalName>(name: T, params: ModalParams<T>) => {
			setModals([...modals, { name, params }]);
		},
		[setModals, modals],
	);

	const closeModal = useCallback(() => {
		// Remove last opened modal
		setModals(modals.slice(0, modals.length));
	}, [setModals, modals]);

	const contextValue = useMemo(
		() => ({ closeModal, openModal }),
		[openModal, closeModal],
	);

	return (
		<ModalContext.Provider value={contextValue}>
			{children}
			{modals.map((data, layer) => (
				<ShowModal {...data} key={layer} />
			))}
		</ModalContext.Provider>
	);
};

export const useModal = (): ModalContextValue => {
	const context = useContext(ModalContext);
	if (!context) {
		throw new Error("useModal must be used within a ModalProvider");
	}
	return context;
};
