import * as z from "zod";

import { createSchema } from "./base";
import {
	ApplicationElementDto,
	applicationElementDtoSchema,
} from "../../element.dto";

/** The type of this input */
export const TYPE = "section";

/** Validation schema for {@link Model} */
export const schema = createSchema(TYPE).extend({
	title: z.string().describe("Title of the section"),
});

/**
 * Application element that is a section.
 * It contains other elements (children)
 */
export type Model = z.infer<typeof schema>;

/** The pivot containing the children of a {@link Model section} */
export interface Pivot {
	/** The element being the child */
	child: ApplicationElementDto;
	/** Order of the child in its parent */
	order: number;

	// It can be guessed that there is probably a parent property in the DB
}

/** Validation schema for {@link Pivot} */
export const pivotSchema = z.object({
	child: z.lazy(() => applicationElementDtoSchema),
	order: z.number().min(0),
	// We must cast here =>
	//	there is a circular reference with the schema and the type can not be inferred
}) as z.ZodType<Pivot>;

/** Validation schema for {@link Dto} */
export const dtoSchema = schema.extend({
	children: z.array(pivotSchema),
});
/** Dto for {@link Model} with its children */
export type Dto = z.infer<typeof dtoSchema>;
