import { HttpRoute } from "@appti/core";

import { createInstanceHttpDefinitions } from "./instance/instance.http";
import { createTemplateHttpDefinitions } from "./template";
import { createVersionHttpDefinitions } from "./version";
import { organizationHttpRoute } from "../organization";

/** Root path of the routes */
const root = organizationHttpRoute.addSegment("application");

/** HTTP configuration for the `Application` feature */
export const APPLICATION_HTTP_CONFIG = {
	children: {
		instances: createInstanceHttpDefinitions(root.addSegment("instances")),
		templates: createTemplateHttpDefinitions(root.addSegment("templates")),
		/**
		 * Http Definitions for `versions`
		 *
		 * Note: `versions` could (also) be a child of `templates`,
		 * 	but we already are under the organization entity, it seems enough
		 * 	e.g.: /application/templates/1/versions/...
		 */
		versions: createVersionHttpDefinitions(root.addSegment("versions")),
	},
	routes: {} satisfies HttpRoute.Definitions,
} as const;

/** HTTP specification for the `Application` feature */
export type ApplicationHttp = HttpRoute.Handlers<
	typeof APPLICATION_HTTP_CONFIG.routes
>;

/** HTTP specification for the `Application instances` feature */
export type ApplicationInstancesHttp = HttpRoute.Handlers<
	// @ts-expect-error -- Why this throw an error ?
	typeof APPLICATION_HTTP_CONFIG.children.instances
>;
/** HTTP specification for the `Application versions` feature */
export type ApplicationVersionsHttp = HttpRoute.Handlers<
	// @ts-expect-error -- Why this throw an error ?
	typeof APPLICATION_HTTP_CONFIG.children.versions
>;
/** HTTP specification for the `Application templates` feature */
export type ApplicationTemplatesHttp = HttpRoute.Handlers<
	typeof APPLICATION_HTTP_CONFIG.children.templates
>;
/** HTTP specification for the `Application Group Permissions` feature */
export type ApplicationGroupPermissionHttp = HttpRoute.Handlers<
	typeof APPLICATION_HTTP_CONFIG.children.versions.children.permissions
>;

/** HTTP specification for the `Application Group Check` feature */
export type ApplicationGroupCheckHttp = HttpRoute.Handlers<
	typeof APPLICATION_HTTP_CONFIG.children.instances.children.groupChecks
>;

// TODO

// --- Endpoints IDEA:

// /api/applications/:id ( =>  Application "Instance")
// 		/api/applications/:id/inputs (POST)
// interface InputCreateDto {
// 	// FROM /api/applications/:id/inputs
// 	inputs: [{ inputId: number; value: string }];
// }

// [optional] /api/application/status
// /api/application/templates
//		/api/application/templates/:id/statuses (POST, PATCH)
// /api/application/versions
// 		/api/application/versions/:id/elements
