// /!\ Do not modify this file to change the environment.
// Use the [file replacements]{@link https://nx.dev/nx-api/next/executors/build} feature instead (in `project.json`).
// It is currently replaced by `.local` on local.

// The exported variable must be named `ENVIRONMENT`

import { Environment } from "./environment.interface";
import { ENVIRONMENT as ENVIRONMENT_PROD } from "./environment.prod";

/** Default exported environment, replaced on builds */
export const ENVIRONMENT: Environment = ENVIRONMENT_PROD;
