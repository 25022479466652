import { styled } from "@mui/joy";

import { Icon, IconName } from "./Icons/Icon";
import { text } from "../../ui-layout/styles/textStyles";
import { theme } from "../../ui-layout/styles/theme";

/**
 * The list of available banner variants
 *
 * @readonly
 * @enum {string}
 */
export enum BannerVariants {
	ERROR = "ERROR",
	INFO = "INFO",
	SUCCESS = "SUCCESS",
}

type StyleDef = {
	[variant in BannerVariants]: {
		backgroundColor: string;
		borderColor: string;
		icon: IconName;
		textColor: string;
	};
};

const styles: StyleDef = {
	[BannerVariants.ERROR]: {
		backgroundColor: theme.palette.red[2],
		borderColor: theme.palette.red[1],
		icon: IconName.error,
		textColor: theme.palette.red[3],
	},
	[BannerVariants.INFO]: {
		backgroundColor: theme.palette.blue[2],
		borderColor: theme.palette.blue.primary,
		icon: IconName.information,
		textColor: theme.palette.blue.primary,
	},
	[BannerVariants.SUCCESS]: {
		backgroundColor: theme.palette.green[1],
		borderColor: theme.palette.green[3],
		icon: IconName.checkmarkOutline,
		textColor: theme.palette.green[2],
	},
};

interface BannerProps {
	children: React.ReactNode;
	showIcon?: boolean;
	variant?: BannerVariants;
}

export const Banner = ({
	children,
	showIcon,
	variant = BannerVariants.INFO,
}: BannerProps) => (
	<BannerContainer variant={variant}>
		{showIcon && (
			<IconWrapper>
				<Icon
					color={styles[variant].borderColor}
					name={styles[variant].icon}
					size={16}
				/>
			</IconWrapper>
		)}
		{children}
	</BannerContainer>
);

const BannerContainer = styled("div")<{ variant: BannerVariants }>`
	display: flex;
	gap: 4px;

	padding: 12px;
	border-radius: 8px;

	border: 1px solid ${props => styles[props.variant].borderColor};
	background: ${props => styles[props.variant].backgroundColor};

	color: ${props => styles[props.variant].textColor};
	${text}
`;

const IconWrapper = styled("div")`
	width: 16px;
	padding-top: 2px;
`;
