import * as z from "zod";

import { BaseModelOmit, createSchema, DenormalizeError } from "./base";
import { SchemaValidationOptions } from "../input.validation-schema";

/** The type of this input */
export const TYPE = "select";

/** Validation schema for {@link Model} */
export const schema = createSchema(TYPE)
	.extend({
		// Note about DB store : simply store and decode as a JSON array
		options: z.array(z.string().min(1)).min(1),

		multiple: z
			.boolean()
			.default(false)
			.describe("Can multiple options be selected"),
		radio: z
			.boolean()
			.default(false)
			.describe("Show the options as `radio-buttons`"),
		// Cases:
		// 	_ => single-choice dropdown
		// 	non-multiple + 2 options => list of radio
		// 	multiple => list of checkboxes
		// (it means the 'radio' prop is not used for now, but we keep it in case this behaviour changes)
	})
	.describe(
		"The input 'select' is supposed to be used with (relatively) small and known options<br>" +
			"For large data (e.g. all cities), use another option",
	);

/** Input of type: select */
export type Model = z.infer<typeof schema>;

/**
 * Create a zod validation schema depends on given input
 *
 * @param input rules validation
 * @param options create input configuration
 * @returns schema generated by given input
 */
export const createInputSchema = (
	input: BaseModelOmit<Model>,
	options: SchemaValidationOptions = { skipRequired: false },
) => {
	// Check if value given is included in given options
	const refinedElementSchema = schema.shape.options.element.refine(
		val => {
			return input.options.includes(val);
		},
		{
			message: "La valeur doit être l'une des options disponibles.",
		},
	);

	const refinedArraySchema = z.array(refinedElementSchema);

	const zodArray = input.multiple
		? refinedArraySchema
		: refinedArraySchema.max(1);
	return input.required && !options.skipRequired
		? zodArray.min(1)
		: zodArray.optional();
};

/**
 * Normalize input to an array of string
 *
 * @param value value to normalize
 *
 * @throws Error
 * @returns a string array
 * @throws {DenormalizeError} if the value is not a valid array
 */
export function denormalize(value: string) {
	try {
		const raw: unknown = JSON.parse(value);

		if (raw === null) {
			return null;
		}

		if (!Array.isArray(raw)) {
			throw new Error();
		}

		return raw.map((item: string) => item);
	} catch {
		// We don't need to catch specific errors, we can mutualize it into a single error
		throw new DenormalizeError(
			`Cannot normalize "${value}" to a array. Expected a string array like "['1', '2']".`,
		);
	}
}
