import * as z from "zod";

import { schema as loginSchema } from "./auth.login.dto";

/** Validation schema for {@link Dto} */
export const schema = loginSchema
	.pick({
		password: true,
		username: true,
	})
	.extend({
		token: z.string().describe('"2FA Token" when setting a password'),
	});

/** DTO when a user sets its password (from forget-password or invitation) */
export type Dto = z.infer<typeof schema>;
