import { Model } from "@appti/core";
import * as z from "zod";

import { applicationElementModelSchema } from "../element";
import { applicationTemplateModelSchema } from "../template";

/** Validation schema for {@link ApplicationVersionModel} */
export const applicationVersionModelSchema = Model.schema.extend({
	fkElement: applicationElementModelSchema.shape._id.describe(
		"FK of the root element representing the application",
	),
	fkTemplate: applicationTemplateModelSchema.shape._id.describe(
		"FK of the template to which this version belongs",
	),
});

/**
 * Version of an application (~= the real template)
 * A version is created when a previous application (that is already used) sees its elements changing
 */
export type ApplicationVersionModel = z.infer<
	typeof applicationVersionModelSchema
>;
