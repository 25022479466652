import { z } from "zod";

/*
 * This functions provides a custom way to handle errors from zod.
 * It allows us to create custom error messages for each type of error,
 * so that we can then translate them in the ErrorMessage component (or elsewhere).
 * Official documentation : https://zod.dev/ERROR_HANDLING
 **/
export const customErrorMap: z.ZodErrorMap = (issue, _) => {
	if (issue.code === z.ZodIssueCode.invalid_string) {
		switch (issue.validation) {
			case "email":
				return { message: "common.form.errors.format-email" };
			case "url":
				return { message: "common.form.errors.format-url" };
			case "uuid":
				return { message: "common.form.errors.format-uuid" };
			default:
				return { message: "common.form.errors.generic" };
		}
	}
	if (issue.code === z.ZodIssueCode.invalid_type) {
		if (issue.received === "undefined") {
			return { message: "common.form.errors.required" };
		}
		switch (issue.expected) {
			case "string":
				return { message: "common.form.errors.format-string" };
			case "number":
				return { message: "common.form.errors.format-number" };
			case "date":
				return { message: "common.form.errors.format-date" };
			default:
				return { message: "common.form.errors.generic-error" };
		}
	}
	if (issue.code === z.ZodIssueCode.too_small) {
		switch (issue.type) {
			case "string":
				return { message: "common.form.errors.string-too-small" };
			case "number":
				return { message: "common.form.errors.number-too-small" };
			default:
				return { message: "common.form.errors.generic-error" };
		}
	}
	if (issue.code === z.ZodIssueCode.too_big) {
		switch (issue.type) {
			case "string":
				return { message: "common.form.errors.string-too-big" };
			case "number":
				return { message: "common.form.errors.number-too-big" };
			default:
				return { message: "common.form.errors.generic-error" };
		}
	}
	if (issue.code === z.ZodIssueCode.invalid_date) {
		return { message: "common.form.errors.format-date" };
	}
	// if (issue.code === z.ZodIssueCode.custom) {
	// 	return { message: `less-than-${(issue.params || {}).minimum}` };
	// }
	return { message: "common.form.errors.generic-error" };
};

/**
 * Function to use in zod superRefine.
 * It checks if two form fields match.
 *
 * @param field1 name of the first field
 * @param field2 name of the second field
 * @returns superRefine function
 */
export const matchFields =
	<T>(field1: keyof T, field2: keyof T) =>
	(data: T, ctx: z.RefinementCtx) => {
		if (data[field1] !== data[field2]) {
			return void ctx.addIssue({
				code: "custom",
				message: "common.form.errors.field-match",
				path: [field2 as string],
			});
		}
	};
