import { HttpRoute } from "@appti/core";

import { UserDto, UserUpdateDto } from "./dtos";

/** Root path of the routes */
const path = HttpRoute.builder("users");
/** Base route with id parameter (e.g. Create, Update, Delete) */
const pathById = path.addSegment({
	param: "id",
	type: "param",
	validation: "number",
});

/** HTTP configuration for the `user` feature */
export const USER_HTTP_CONFIG = {
	routes: {
		/** Update {@link UserDto} (only the connected user) */
		update: pathById.patch<(body: UserUpdateDto) => Promise<UserDto>>(),
	} satisfies HttpRoute.Definitions,
} as const;

/** HTTP specification for the `user` feature */
export type UserHttp = HttpRoute.Handlers<typeof USER_HTTP_CONFIG.routes>;
