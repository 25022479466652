import { Switch as SwitchJoy, styled, switchClasses } from "@mui/joy";

/**
 * disabled: Whether the switch is disabled
 * error: Whether the switch has an error
 * label: The label to display next to the switch
 * name: The form name field of the switch
 * value: The value to return when the switch is checked - useless for checkbox, but raises an warning if passed down to Joy UI checkbox
 */
export interface SwitchProps {
	disabled?: boolean;
	error?: boolean;
	label?: string;
	name?: string;
	value?: boolean;
}

export const Switch = ({
	error: _error,
	label,
	name,
	value,
	...rest
}: SwitchProps): JSX.Element => (
	<SwitchStyled
		checked={value}
		endDecorator={label}
		id={name}
		slotProps={{ input: { name } }}
		{...rest}
	/>
);

const SwitchStyled = styled(SwitchJoy)`
	--Switch-thumbSize: 16px;
	--Switch-trackHeight: 20px;
	--Switch-trackWidth: 36px;
	--Switch-trackBackground: ${({ theme }) => theme.vars.palette.grey[2]};
	&:hover {
		--Switch-trackBackground: ${({ theme }) => theme.vars.palette.grey[2]};
	}

	&.${switchClasses.checked} {
		--Switch-trackBackground: ${({ theme }) => theme.vars.palette.green[3]};
		&:hover {
			--Switch-trackBackground: ${({ theme }) =>
				theme.vars.palette.green[3]};
		}
	}

	&.${switchClasses.disabled} .${switchClasses.input} {
		cursor: not-allowed !important;
		pointer-events: all !important;
	}
	&.${switchClasses.disabled} {
		--Switch-trackBackground: ${({ theme }) => theme.vars.palette.grey[2]};
		&:hover {
			--Switch-trackBackground: ${({ theme }) =>
				theme.vars.palette.grey[2]};
		}
	}
	&.${switchClasses.disabled}.${switchClasses.checked} {
		--Switch-trackBackground: ${({ theme }) => theme.vars.palette.grey[4]};
		&:hover {
			--Switch-trackBackground: ${({ theme }) =>
				theme.vars.palette.grey[4]};
		}
	}
`;
