import * as z from "zod";

import * as Options from "./options";
import { BaseModel, DISCRIMINATION_KEY } from "./options/base";

/** Validation schema for {@link Model} */
export const schema = z.discriminatedUnion(DISCRIMINATION_KEY, [
	Options.Checkbox.schema,
	Options.Date.schema,
	Options.Number.schema,
	Options.Select.schema,
	Options.Text.schema,
]);

/** An input of many types. */
export type Model = z.infer<typeof schema>;

/** Known type for {@link Model} */
export type Type = Model[typeof DISCRIMINATION_KEY];
/** List of available types for {@link Model} */
export const TYPES: readonly Type[] = schema.options.map(
	({ shape }) => shape[DISCRIMINATION_KEY].value,
);

/** Common model for all inputs */
export type Common = BaseModel<Type>;

export { DISCRIMINATION_KEY, baseSchema } from "./options/base";
export * as Options from "./options";
