import { AccountFormModal } from "./AccountFormModal";
import { ConfirmModal } from "./ConfirmModal";
import { ApplicationUpdateStepModal } from "../../../application/templates/ApplicationUpdateStepModal";
import { AddUserModal } from "../../../user/templates/AddUserModal";

export const modalsList = {
	accountForm: AccountFormModal,
	addUser: AddUserModal,
	applicationUpdateStep: ApplicationUpdateStepModal,
	confirm: ConfirmModal,
} as const satisfies Record<string, (props: never) => JSX.Element>;

type ModalList = typeof modalsList;

/** Name of known modals */
export type ModalName = keyof ModalList;
/** Get the parameters for a modal */
export type ModalParams<T extends ModalName> = Parameters<ModalList[T]>[0];

/** Stored data a layered modal item */
export interface ModalData<K extends ModalName = ModalName> {
	name: K;
	params: ModalParams<K>;
}

/**
 * Show a modal
 *
 * @param props of the data to show
 * @returns a JSX element with the model to open
 */
export function ShowModal<T extends ModalName>(props: ModalData<T>) {
	// Need cast due to the dict inference
	return (modalsList[props.name] as (param: unknown) => JSX.Element)(
		props.params,
	);
}
