import * as z from "zod";

import { BaseModelOmit, createSchema, DenormalizeError } from "./base";
import { SchemaValidationOptions } from "../input.validation-schema";

/** The type of this input */
export const TYPE = "checkbox";

/** Validation schema for {@link Model} */
export const schema = createSchema(TYPE);

/** Input of type: checkbox */
export type Model = z.infer<typeof schema>;

/**
 * Create a zod validation schema depends on given input
 *
 * @param input rules validation
 * @param options create input configuration
 * @returns schema generated by given input
 */
export const createInputSchema = (
	input: BaseModelOmit<Model>,
	options: SchemaValidationOptions = { skipRequired: false },
) => {
	const zodSchema: z.ZodBoolean | z.ZodOptional<z.ZodBoolean> = z.boolean();
	if (!input.required || options?.skipRequired) {
		return zodSchema.optional();
	}
	return zodSchema.refine(val => val, {
		message: "Le booléen doit être true",
	});
};

/**
 * Normalize input to a boolean
 *
 * @param value value to normalize
 *
 * @throws Error
 * @returns a boolean
 */
export function denormalize(value: string): boolean {
	try {
		return JSON.parse(value) as boolean;
	} catch {
		throw new DenormalizeError(
			`Cannot normalize "${value}" to a boolean. Expected '"true"' or '"false"'.`,
		);
	}
}
