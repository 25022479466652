import * as z from "zod";

import { InputModel, ValidationSchema } from "./index";
import { Checkbox, Date, Number, Select, Text } from "./options";

export type Input = Omit<InputModel, "_id" | "createdAt" | "updatedAt">;

/** Schema option */
export interface SchemaValidationOptions {
	skipRequired?: boolean;
}

const schemaValidationRecord = {
	[Number.TYPE]: (input, options) => {
		return Number.createInputSchema(input, options);
	},
	[Text.TYPE]: (input, options) => {
		return Text.createInputSchema(input, options);
	},
	[Select.TYPE]: (input, options) => {
		return Select.createInputSchema(input, options);
	},
	[Date.TYPE]: (input, options) => {
		return Date.createInputSchema(input, options);
	},
	[Checkbox.TYPE]: (input, options) => {
		return Checkbox.createInputSchema(input, options);
	},
} satisfies SchemaValidation;

type NormalizedValues =
	| ReturnType<typeof Select.denormalize>
	| ReturnType<typeof Number.denormalize>
	| ReturnType<typeof Text.denormalize>
	| ReturnType<typeof Date.denormalize>
	| ReturnType<typeof Checkbox.denormalize>;

const inputDenormalizationRecord = {
	[Number.TYPE]: normalizedValue => {
		return Number.denormalize(normalizedValue);
	},
	[Text.TYPE]: normalizedValue => {
		return Text.denormalize(normalizedValue);
	},
	[Select.TYPE]: normalizedValue => {
		return Select.denormalize(normalizedValue);
	},
	[Date.TYPE]: normalizedValue => {
		return Date.denormalize(normalizedValue);
	},
	[Checkbox.TYPE]: normalizedValue => {
		return Checkbox.denormalize(normalizedValue);
	},
} satisfies Normalization;

type Normalization = {
	[K in InputModel["type"]]: (rawValue: string) => NormalizedValues;
};

type SchemaValidation = {
	[K in InputModel["type"]]: (
		input: Extract<InputModel, { type: K }>,
		options: SchemaValidationOptions,
	) => z.ZodTypeAny;
};

/**
 * Generate a new zod schema depends on given input
 *
 * @param input configuration used to created zod schema
 *
 * @param options create input configuration
 * @throws UnprocessableEntityException
 * @returns a zod schema
 */
export function generateSchemaValidation<const T extends Input>(
	input: T,
	options: SchemaValidationOptions,
): ReturnType<(typeof schemaValidationRecord)[T["type"]]> {
	const handler = schemaValidationRecord[input.type];
	return handler(input as never, options) as never;
}

/**
 * denormalize input depends on given input
 *
 * @param type type of input
 * @param value raw value of input
 *
 * @returns denormalized value
 */
export function denormalizeValue<const T extends Input>(
	type: InputModel["type"],
	value: string,
): ReturnType<(typeof inputDenormalizationRecord)[T["type"]]> {
	return inputDenormalizationRecord[type](value as never) as never;
}

/**
 * normalize input to a string
 *
 * @param value value to normalize
 *
 * @returns normalized value
 */
export function normalizeValue(value: unknown): string {
	return JSON.stringify(value);
}

/**
 * Generate zod schema and denormalize input
 *
 * @param input type of input
 * @param value raw value of input
 * @param options create input configuration
 */
export function validate(
	input: InputModel,
	value: string,
	options: SchemaValidationOptions = {},
) {
	const zodSchema = ValidationSchema.generateSchemaValidation(input, options);

	// Actually can be an array of Date or string
	// TODO: Later think about return all errors at once instead one by one
	const values = ValidationSchema.denormalizeValue(input.type, value);

	zodSchema.parse(values);
}
