import { useMutation, useQuery } from "@tanstack/react-query";
import { Formik, useFormikContext } from "formik";
import { useParams } from "next/navigation";
import { useTranslation } from "next-i18next";
import { JSX } from "react";
import * as z from "zod";
import { toFormikValidationSchema } from "zod-formik-adapter";

import { OrganizationGroupRepository } from "../../../services/api/repositories/OrganizationGroupRepository";
import { organizationUserRepository } from "../../../services/api/repositories/organizationUserRepository";
import { FetchError } from "../../../services/api/utils/FetchError";
import { Icon, IconName } from "../../ui-atoms/components/Icons/Icon";
import { ToastVariants } from "../../ui-atoms/components/Toast";
import { showToast } from "../../ui-atoms/services/showToast";
import { Button, ButtonVariants } from "../../ui-form/components/Button/Button";
import {
	LayoutModal,
	ModalProps,
} from "../../ui-layout/components/Modal/LayoutModal";
import {
	AddUserForm,
	addUserFormValuesSchema,
} from "../components/AddUserForm";

/** Props with refresh function use to update user list */
export type AddUserModalProps = Pick<ModalProps, "onClose" | "open"> & {
	refresh: () => void;
};

export const AddUserModal = ({
	onClose,
	open,
	...props
}: AddUserModalProps): JSX.Element => {
	const { t } = useTranslation("common");
	const { organizationId } = useParams<{ organizationId: string }>();

	const initialValues: FormValues = {
		email: "",
		groupId: 1,
		isAdmin: false,
		name: { first: "", last: "" },
	};

	const { data: groups, error } = useQuery({
		...OrganizationGroupRepository.findAndCount({}, Number(organizationId)),
	});
	if (error) {
		showToast({
			message: t("pages.users.add-user.errors.group_error"),
			title: t("common.api.errors.generic.message"),
			variant: ToastVariants.ERROR,
		});
	}

	const { mutateAsync } = useMutation({
		...organizationUserRepository.create(+organizationId),
		meta: {
			onError: ({ response }: FetchError) => {
				if (response.status === 409) {
					showToast({
						message: t(
							"pages.users.add-user.errors.email_already_used",
						),
						title: "Error",
						variant: ToastVariants.ERROR,
					});
					return;
				}
				if (response.status === 400) {
					showToast({
						message: t("pages.users.add-user.errors.bad_request"),
						title: "Error",
						variant: ToastVariants.ERROR,
					});
				}
			},
			onSuccess: () => {
				showToast({
					message: t("pages.users.add-user.success"),
					title: t("common.api.success.generic-title"),
					variant: ToastVariants.SUCCESS,
				});
				props.refresh();
				onClose?.();
			},
		},
	});

	const onSubmitHandler = async (values: FormValues) => {
		void (await mutateAsync({
			...values,
			groupId: Number(values.groupId),
		}));
	};

	const Actions = () => {
		const { handleSubmit, isSubmitting } = useFormikContext<FormValues>();

		return (
			<>
				<Button
					label={t("common.button.cancel")}
					onClick={onClose}
					variant={ButtonVariants.SECONDARY}
				/>
				<Button
					isLoading={isSubmitting}
					label={t("pages.users.add-user.button-invite-user")}
					onClick={handleSubmit}
					startDecorator={
						<Icon name={IconName.checkmark} size={20} />
					}
				/>
			</>
		);
	};

	const Body: ModalProps["body"] = (
		<AddUserForm groups={groups?.data ?? []} />
	);

	return (
		<Formik<FormValues>
			initialValues={initialValues}
			onSubmit={onSubmitHandler}
			validationSchema={toFormikValidationSchema(validationSchema)}
		>
			{() => (
				<LayoutModal
					actions={Actions}
					body={Body}
					onClose={onClose}
					open={open}
					title={t("pages.users.add-user.title")}
				/>
			)}
		</Formik>
	);
};

const validationSchema = addUserFormValuesSchema;
type FormValues = z.infer<typeof validationSchema>;
