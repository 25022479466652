import { createQueryResultsSchema } from "@appti/core";
import * as z from "zod";

import { organizationUserDtoSchema } from "./organization-user.dto";

/** Validation schema for {@link UserQueryResultsDto} */
export const organizationUserQueryResultsSchema = createQueryResultsSchema(
	organizationUserDtoSchema,
);

/** Results for {@link UserDto} for a query */
export type OrganizationUserQueryResultsDto = z.infer<
	typeof organizationUserQueryResultsSchema
>;
