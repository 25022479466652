import * as z from "zod";

import { APPLICATION_INSTANCE_STATUS as STATUS } from "./instance.status";

/** Validation schema for {@link ApplicationInstanceStep} */
export const applicationInstanceStepSchema = z.enum([
	...STATUS.draft.schema.options,
	...STATUS.progress.schema.options,
	...STATUS.done.schema.options,
	...STATUS.cancelled.schema.options,
]);
/** Represent all available steps for one instance */
export type ApplicationInstanceStep = z.infer<
	typeof applicationInstanceStepSchema
>;

/** The step that are in progress */
export type ApplicationInstanceStepProgress = z.infer<
	typeof STATUS.progress.schema
>;
