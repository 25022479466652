import { Model } from "@appti/core";
import * as z from "zod";

import { userRoleSchema } from "./role";
import { userModelSchema } from "../../../user/user.model";
import { organizationModelSchema } from "../../organization.model";

/** Validation schema for {@link UserRoleModel} */
export const userRoleModelSchema = Model.schema.extend({
	fkOrganization: organizationModelSchema.shape._id.describe(
		"The organization, owner of user/role relation",
	),
	fkUser: userModelSchema.shape._id.describe("The user the role is given to"),

	role: userRoleSchema,
});

/** The organization-role defines the role of an user in an organization */
export type UserRoleModel = z.infer<typeof userRoleModelSchema>;
