import { Mutation, Query, QueryKey } from "@tanstack/react-query";
import { TFunction } from "i18next";
import { AppRouterInstance } from "next/dist/shared/lib/app-router-context.shared-runtime";

import { FetchError } from "./FetchError";
import { ToastVariants } from "../../../features/ui-atoms/components/Toast";
import { showToast } from "../../../features/ui-atoms/services/showToast";
import { ROUTES } from "../../routes";

// const responseMessages: Record<number, string> = {
// 	200: "common.api.success.messages.200",
// 	201: "common.api.success.messages.201",
// 	204: "common.api.success.messages.204",
// };
const errorMessages: Record<number, string> = {
	400: "common.api.errors.messages.400",
	401: "common.api.errors.messages.401",
	403: "common.api.errors.messages.403",
	404: "common.api.errors.messages.404",
};

export const handleGenericMutationErrors = (
	error: FetchError,
	variables: unknown,
	context: unknown,
	mutation: Mutation<unknown, unknown, unknown, unknown>,
	router: AppRouterInstance,
	t: TFunction<"translation", undefined>,
): void => {
	const errorTitle = "common.api.errors.generic-title";
	let errorMessage = "common.api.errors.messages.generic";
	const { status } = error.response;

	if (mutation.meta?.errorMessage) {
		errorMessage = mutation.meta?.errorMessage as string;
	} else if (status in errorMessages) {
		errorMessage = t(errorMessages[status]);
	}

	if (status !== 401) {
		showToast({
			message: t(errorMessage),
			title: t(errorTitle),
			variant: ToastVariants.ERROR,
		});
	}
	handleHTTPStatus(status, router);
};

export const handleGenericMutationSuccess = (
	data: unknown,
	variables: unknown,
	context: unknown,
	mutation: Mutation<unknown, unknown, unknown, unknown>,
	router: AppRouterInstance,
	t: TFunction<"translation", undefined>,
): void => {
	showToast({
		message: t("common.api.success.messages.generic"),
		title: t("common.api.success.generic-title"),
		variant: ToastVariants.SUCCESS,
	});
};

export const handleGenericQueryErrors = (
	error: Error,
	query: Query<unknown, unknown, unknown, QueryKey>,
	router: AppRouterInstance,
	t: TFunction<"translation", undefined>,
): void => {
	const errorTitle = "common.api.errors.generic-title";
	let errorMessage = "common.api.errors.messages.generic";

	if (!(error instanceof FetchError)) {
		showToast({
			message: t(errorMessage),
			title: t(errorTitle),
			variant: ToastVariants.ERROR,
		});
		return;
	}

	const { status } = error.response;
	if (query.meta?.errorMessage) {
		errorMessage = query.meta?.errorMessage as string;
	} else if (status in errorMessages) {
		errorMessage = errorMessages[status];
	}

	if (status !== 401) {
		showToast({
			message: t(errorMessage),
			title: t(errorTitle),
			variant: ToastVariants.ERROR,
		});
	}

	handleHTTPStatus(error.response.status, router);
};

export const handleHTTPStatus = (
	code: number,
	router: AppRouterInstance,
): void => {
	if (code === 401) {
		router.push(ROUTES.auth.login.url());
	}
};
