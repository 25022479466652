import * as z from "zod";

import { BaseModelOmit, createSchema, DenormalizeError } from "./base";
import { SchemaValidationOptions } from "../input.validation-schema";

/** The type of this input */
export const TYPE = "number";

/** Validation schema for {@link Model} */
export const schema = createSchema(TYPE).extend({
	max: z.number().nullable().default(null).describe("Maximum accepted value"),
	min: z.number().nullable().default(null).describe("Minimum accepted value"),
	step: z
		.number()
		.nullable()
		.default(null)
		.describe("The step to use (2 => [0,2,4,6]). This is not a constraint"),
});

/** Input of type: number */
export type Model = z.infer<typeof schema>;

/**
 * Create a zod validation schema depends on given input
 *
 * @param input rules validation
 * @param options create input configuration
 * @returns schema generated by given input
 */
export const createInputSchema = (
	input: BaseModelOmit<Model>,
	options: SchemaValidationOptions = { skipRequired: false },
) => {
	let schema = z.number();
	if (input.max || input.max === 0) {
		schema = schema.max(input.max);
	}
	if (input.min || input.min === 0) {
		schema = schema.min(input.min);
	}

	// Warning : This condition should be always the last of this function
	if (!input.required || options.skipRequired) {
		return schema.optional().nullable();
	}
	return schema;
};

/**
 * Normalize input to a number
 *
 * @param value value to normalize
 * @returns normalized value
 * @throws {DenormalizeError} if the value is not a valid date
 */
export function denormalize(value: string) {
	try {
		const val: unknown = JSON.parse(value);
		if (val === null) {
			return null;
		}
		// Do not authorize empty string to abort incoherent behavior (eg: Number("") => 0)
		if (val === "" || typeof val === "boolean" || val === undefined) {
			throw new Error();
		}

		if (Number.isNaN(val)) {
			throw new Error();
		}

		return +val;
	} catch {
		throw new DenormalizeError(
			`Cannot normalize "${value}" to a number. Expected a string number like "90".`,
		);
	}
}
