import { Model } from "@appti/core";
import * as z from "zod";

/** The discrimination key for the inputs */
export const DISCRIMINATION_KEY = "type";

export class DenormalizeError extends Error {}

/**
 * The base schema that all inputs extends.
 * Also, because the shape is not easily reachable from the discriminated union
 */
export const baseSchema = Model.schema.extend({
	required: z.boolean().describe("Is the input required?"),
	tooltip: z.string().describe("The tooltip to display (Can be markdown)"),
	// TODO: needed ?
	// nullable: z.boolean().describe("Is the input nullable?"),
});

/**
 * Creates input schemas.
 *
 * @param type to set to the schema
 * @returns base schema for the discriminations
 */
export function createSchema<const T extends string>(type: T) {
	return baseSchema.extend({ [DISCRIMINATION_KEY]: z.literal(type) });
}

/** Base model from {@link createSchema} */
export type BaseModel<T extends string> = z.infer<
	ReturnType<typeof createSchema<T>>
>;

/** Common keys that do not contain "input-information */
type Common = keyof Pick<BaseModel<string>, "_id" | "createdAt" | "updatedAt">;

export type BaseModelOmit<T extends BaseModel<string>> = Omit<T, Common>;
