import {
	ApplicationValueDto,
	ApplicationValueExtendedDto,
} from "~/common/application/dtos/value.dto";
import {
	ApplicationInstanceCountedByStatuses,
	ApplicationInstanceCountedByStatusesQuery,
	ApplicationInstanceCreateDto,
	ApplicationInstanceCreateGroupCheckDto,
	ApplicationInstanceDto,
	ApplicationInstanceQueryDto,
	ApplicationInstanceQueryResultsDto,
	ApplicationInstanceUpdateInputDto,
	ApplicationInstanceUpdateStatusDto,
	WithUpdateInputError,
} from "~/common/application/instance/dtos";
import { ApplicationGroupCheckDto } from "~/common/application/instance/group-check/dtos";
import { GenericOrganizerEmailDtoQuery } from "~/common/auth";

import { apiEndpoints } from "../endpoints";
import {
	CustomUseMutationOptions,
	CustomUseQueryOptions,
} from "../reactQueryConfig";
import { FetchError } from "../utils/FetchError";

export const ApplicationInstanceRepository = {
	countByStatus: (
		orgId: number,
		query: ApplicationInstanceCountedByStatusesQuery = {},
	): CustomUseQueryOptions<
		ApplicationInstanceCountedByStatuses,
		FetchError
	> => ({
		queryFn: () =>
			apiEndpoints.applicationInstances.countByStatus(orgId, query),
		queryKey: ["instance", "instanceCountByStatus"],
	}),
	create: (
		orgId: number,
	): CustomUseMutationOptions<
		ApplicationInstanceDto,
		FetchError,
		ApplicationInstanceCreateDto
	> => ({
		mutationFn: body =>
			apiEndpoints.applicationInstances.create(orgId, body),
		mutationKey: [],
	}),
	findAndCount: (
		orgId: number,
		query: ApplicationInstanceQueryDto = {},
	): CustomUseQueryOptions<
		ApplicationInstanceQueryResultsDto,
		FetchError
	> => ({
		queryFn: () =>
			apiEndpoints.applicationInstances.findAndCount(orgId, query),
		queryKey: ["instance", "instanceFindAndCount"],
	}),
	findOne: (
		orgId: number,
		instanceId: ApplicationInstanceDto["_id"],
		query: GenericOrganizerEmailDtoQuery = {},
	): CustomUseQueryOptions<ApplicationInstanceDto, FetchError> => ({
		queryFn: () =>
			apiEndpoints.applicationInstances.findOne(orgId, instanceId, query),
		queryKey: ["instance", "instanceFindOne", instanceId],
	}),
	getInputValues: (
		orgId: number,
		instanceId: ApplicationInstanceDto["_id"],
		query: GenericOrganizerEmailDtoQuery = {},
	): CustomUseQueryOptions<ApplicationValueExtendedDto[], FetchError> => ({
		queryFn: () =>
			apiEndpoints.applicationInstances.getInputValues(
				orgId,
				instanceId,
				query,
			),
		queryKey: ["values", "instanceGetInputValues", instanceId, orgId],
	}),
	groupChecks: {
		create: (
			orgId: number,
			instanceId: ApplicationInstanceDto["_id"],
		): CustomUseMutationOptions<
			ApplicationGroupCheckDto | undefined,
			FetchError,
			ApplicationInstanceCreateGroupCheckDto
		> => ({
			mutationFn: body =>
				apiEndpoints.applicationInstances.groupChecks.create(
					orgId,
					instanceId,
					body,
				),
			mutationKey: [],
		}),
		findAndCount: (
			orgId: number,
			instanceId: number,
		): CustomUseQueryOptions<ApplicationGroupCheckDto[], FetchError> => ({
			queryFn: () =>
				apiEndpoints.applicationInstances.groupChecks.findAndCount(
					orgId,
					instanceId,
				),
			queryKey: ["groupChecks", "instanceGroupChecks", instanceId],
		}),
	},
	updateInput: (
		orgId: number,
		instanceId: number,
		query: GenericOrganizerEmailDtoQuery = {},
	): CustomUseMutationOptions<
		WithUpdateInputError<ApplicationValueDto>,
		FetchError,
		ApplicationInstanceUpdateInputDto[]
	> => ({
		mutationFn: body =>
			apiEndpoints.applicationInstances.updateInput(
				orgId,
				instanceId,
				body,
				query,
			),
		mutationKey: [],
	}),
	updateStatus: (
		orgId: number,
		instanceId: ApplicationInstanceDto["_id"],
		query: GenericOrganizerEmailDtoQuery = {},
	): CustomUseMutationOptions<
		ApplicationInstanceDto,
		FetchError,
		ApplicationInstanceUpdateStatusDto
	> => ({
		mutationFn: body =>
			apiEndpoints.applicationInstances.updateStatus(
				orgId,
				instanceId,
				body,
				query,
			),
		mutationKey: [],
	}),
	validate: (
		orgId: number,
		instanceId: number,
	): CustomUseMutationOptions<void, FetchError, unknown> => ({
		mutationFn: () =>
			apiEndpoints.applicationInstances.validate(orgId, instanceId),
		mutationKey: [],
	}),
};
