import * as z from "zod";

import { userDtoSchema } from "./user.dto";
import { groupDtoSchema } from "../../organization/group/dtos/group.dto";
import { userRoleDtoSchema } from "../../organization/user/role/dtos/role.dto";

/** Validation schema for {@link GroupDto} */
export const userExtendedDtoSchema = userDtoSchema.extend({
	// Note: this could technically lazy-refer to another "DtoExtended"
	//	But to avoid as it become quickly out of hand to satisfy the entities
	groups: z.array(groupDtoSchema),
	roles: z.array(userRoleDtoSchema),
});
/** Extension of {@link userDtoSchema} with some relations */
export type UserExtendedDto = z.infer<typeof userExtendedDtoSchema>;
