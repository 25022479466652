import { APPLICATION_HTTP_CONFIG } from "~/common/application";
import { ApplicationTemplateDto } from "~/common/application/template/dtos";
import { ApplicationTemplateQueryResultsDto } from "~/common/application/template/dtos/template.query-results.dto";
import { ApplicationTemplateQueryDto } from "~/common/application/template/dtos/template.query.dto";

import { RequestMethod, fetchRequest } from "../utils/utils";

const { templates: route } = APPLICATION_HTTP_CONFIG.children;

export const apiApplicationTemplateEndpoints = {
	findAndCount: (
		orgId: number,
		query: ApplicationTemplateQueryDto,
	): Promise<ApplicationTemplateQueryResultsDto> =>
		fetchRequest({
			endpoint: route.findAndCount.path({ orgId }),
			method: RequestMethod.GET,
			query,
		}),
	findById: (
		orgId: number,
		templateId: ApplicationTemplateDto["_id"],
	): Promise<ApplicationTemplateDto> =>
		fetchRequest({
			endpoint: route.findById.path({
				orgId,
				templateId,
			}),
			method: RequestMethod.GET,
		}),
};
