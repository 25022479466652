import * as z from "zod";

import { applicationInstanceModelSchema } from "../../application/instance";

/**
 * A generic validation schema that should be used when a request needs
 * to send the organizerEmail as a query for logged out usage
 */
export const genericOrganizerEmailQueryDtoSchema = z.object({
	email: applicationInstanceModelSchema.shape.organizerEmail.optional(),
});
export type GenericOrganizerEmailDtoQuery = z.infer<
	typeof genericOrganizerEmailQueryDtoSchema
>;
