/** Parameter needed to en/decode a token for an instance */
export interface instanceCredentials {
	email: string;
	id: string;
}

/** Use to split information in token */
const separator = "+|";
/**
 * Encode a text to base64
 *
 * @param params contains email and id credentials to encode
 * @returns encoded string
 */
export function encode(params: instanceCredentials): string {
	const textToEncode = `${params.email}${separator}${params.id}`;
	return Buffer.from(textToEncode).toString("base64");
}

/**
 * Encode a text to base64
 *
 * @param encoded string to decode
 * @returns decoded instance credentials
 */
export function decode(encoded: string): instanceCredentials {
	const decodedStr = Buffer.from(encoded, "base64")
		.toString("utf8")
		.split(separator);
	return {
		email: decodedStr[0],
		id: decodedStr[1],
	};
}
