import { useRouter } from "next/router";

import { ROUTES } from "../../../services/routes";

/**
 * This hook checks if the current route is a public route
 * (e.g. a route that can be accessible without authentication, outside of login pages)
 *
 * @returns
 * isPublicRoute: Whether the current route is a public route
 */
export const useIsPublicRoute = () => {
	const router = useRouter();

	const isPublicRoute =
		ROUTES.applications.read.view.isActive(router.asPath) ||
		ROUTES.applications.read.success.isActive(router.asPath);

	return { isPublicRoute };
};
