import { UserRole } from "../role";
import { UserRoleModel } from "../role.model";

/**
 * Minimal type for a role with its organization.
 * Can be used with incomplete data (e.g. on create)
 */
export type RoleWithOrganization = Pick<
	UserRoleModel,
	"role" | "fkOrganization"
>;

/**
 * Looks for role(s) in an organization
 *
 * @param roles to look into
 * @param orgId id of the organization to filter
 * @returns a function to look for any {@link UserRole}
 */
export function hasRoleInOrganization(
	roles: readonly RoleWithOrganization[],
	orgId: number,
) {
	const orgRoles = roles.filter(
		({ fkOrganization }) => fkOrganization === orgId,
	);

	return (lookFor: UserRole) => orgRoles.some(({ role }) => role === lookFor);
}

/**
 * Has an admin role in an organization
 *
 * @param roles to look into
 * @param orgId id of the organization to filter
 * @returns if one of the roles is `ADMIN`
 */
export function isAdminInOrganization(
	roles: readonly RoleWithOrganization[],
	orgId: number,
) {
	return hasRoleInOrganization(roles, orgId)("ADMIN");
}

/**
 * Has a moderator role in an organization
 *
 * @param roles to look into
 * @param orgId id of the organization to filter
 * @returns if one of the roles is `MODERATOR`
 */
export function isModeratorInOrganization(
	roles: readonly RoleWithOrganization[],
	orgId: number,
) {
	return hasRoleInOrganization(roles, orgId)("MODERATOR");
}

/**
 * Has a secretary role in an organization
 *
 * @param roles to look into
 * @param orgId id of the organization to filter
 * @returns if one of the roles is `SECRETARY`
 */
export function isSecretaryInOrganization(
	roles: readonly RoleWithOrganization[],
	orgId: number,
) {
	return hasRoleInOrganization(roles, orgId)("SECRETARY");
}

/**
 * Has a role in an organization (aka is an employee)
 *
 * @param roles to look into
 * @param orgId id of the organization to filter
 * @returns if has a role in the organization
 */
export function isEmployeeInOrganization(
	roles: readonly RoleWithOrganization[],
	orgId: number,
) {
	return (
		hasRoleInOrganization(roles, orgId)("ADMIN") ||
		hasRoleInOrganization(roles, orgId)("MODERATOR") ||
		hasRoleInOrganization(roles, orgId)("SECRETARY")
	);
}
