import { ORGANIZATION_HTTP_CONFIG } from "~/common/organization";
import {
	OrganizationUserCreateDto,
	OrganizationUserDto,
	OrganizationUserQueryDto,
	OrganizationUserQueryResultsDto,
	OrganizationUserUpdateDto,
} from "~/common/organization/user/dtos";

import { RequestMethod, fetchRequest } from "../utils/utils";

const { users } = ORGANIZATION_HTTP_CONFIG.children;

export const apiUserOrganizationEndpoints = {
	create: (
		orgId: number,
		body: OrganizationUserCreateDto,
	): Promise<OrganizationUserDto> =>
		fetchRequest({
			body,
			endpoint: `${users.create.path({ orgId })}`,
			method: RequestMethod.POST,
		}),
	findAndCount: (
		query: OrganizationUserQueryDto,
		orgId: number,
	): Promise<OrganizationUserQueryResultsDto> =>
		fetchRequest({
			endpoint: `${users.findAndCount.path({ orgId })}`,
			method: RequestMethod.GET,
			query,
		}),
	findById: (
		userId: OrganizationUserDto["_id"],
		orgId: number,
	): Promise<OrganizationUserDto> =>
		fetchRequest({
			endpoint: `${users.findById.path({
				orgId,
				userId,
			})}`,
			method: RequestMethod.GET,
		}),
	updateById: (
		userId: OrganizationUserDto["_id"],
		orgId: number,
		body: OrganizationUserUpdateDto,
	): Promise<void> =>
		fetchRequest<null, never, OrganizationUserUpdateDto>({
			body,
			endpoint: users.update.path({ orgId, userId }),
			method: RequestMethod.PATCH,
		}),
};
