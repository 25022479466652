import * as z from "zod";

import { schema as successSchema } from "./auth.success.dto";
import { userExtendedDtoSchema } from "../../user/dtos/user.extended.dto";

/** Validation schema for {@link Dto} */
export const schema = successSchema
	.pick({ expireOn: true, issuedAt: true })
	// The connected user can read its own data
	.extend({ user: userExtendedDtoSchema });

export type Dto = z.infer<typeof schema>;
