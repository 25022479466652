import { ORGANIZATION_HTTP_CONFIG } from "~/common/organization";
import {
	GroupQueryDto,
	GroupQueryResultsDto,
} from "~/common/organization/group/dtos";

import { RequestMethod, fetchRequest } from "../utils/utils";

const { groups } = ORGANIZATION_HTTP_CONFIG.children;

export const apiOrganizationGroupEndpoints = {
	findAndCount: (
		query: GroupQueryDto,
		orgId: number,
	): Promise<GroupQueryResultsDto> =>
		fetchRequest<GroupQueryResultsDto, GroupQueryResultsDto>({
			endpoint: `${groups.findAndCount.path({ orgId })}`,
			method: RequestMethod.GET,
			query,
		}),
};
