import { APPLICATION_HTTP_CONFIG } from "~/common/application";
import {
	ApplicationVersionDto,
	ApplicationVersionExtendedDto,
	ApplicationVersionQueryDto,
	ApplicationVersionQueryResultsDto,
} from "~/common/application/version/dtos";
import { ApplicationGroupPermissionQueryResultDto } from "~/common/application/version/group-permission/dto/group-permission.query-result.dto";

import { ENVIRONMENT } from "../../../environment";
import { fetchRequest, RequestMethod } from "../utils/utils";

const { versions: route } = APPLICATION_HTTP_CONFIG.children;
const backendConfig = ENVIRONMENT.backend();

export const apiApplicationVersionEndpoints = {
	findAndCount: (
		orgId: number,
		query: ApplicationVersionQueryDto,
	): Promise<ApplicationVersionQueryResultsDto> =>
		fetchRequest({
			endpoint: route.findAndCount.path({ orgId }),
			method: RequestMethod.GET,
			query,
		}),
	findById: (
		orgId: number,
		versionId: ApplicationVersionDto["_id"],
	): Promise<ApplicationVersionExtendedDto> =>
		fetchRequest({
			endpoint: route.findById.path({
				orgId,
				versionId,
			}),
			method: RequestMethod.GET,
		}),
	permissions: (
		orgId: number,
		versionId: ApplicationVersionDto["_id"],
	): Promise<ApplicationGroupPermissionQueryResultDto> =>
		fetchRequest({
			endpoint: route.children.permissions.findAndCount.path({
				orgId,
				versionId,
			}),
			// Ensure we retrieve every permission at once and don't need to go through pagination
			method: RequestMethod.GET,
			query: { limit: backendConfig.limitAll },
		}),
};
