import * as z from "zod";

import { userModelSchema } from "../../user";

/** Validation schema for {@link Dto} */
export const schema = userModelSchema.pick({ email: true }).extend({
	orgId: z.number(),
});

/** DTO to start a reset-password action by email*/
export type Dto = z.infer<typeof schema>;
