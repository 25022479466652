import { HttpRoute } from "@appti/core";

import { GroupQueryDto, GroupQueryResultsDto } from "./dtos";

/**
 * Create routes definitions for `group` features
 *
 * @param base of the HttpRoute builder
 * @returns route definitions
 */
export function createGroupHttpDefinitions<
	const BASE extends HttpRoute.BuilderAny,
>(base: BASE) {
	const _pathById = base.addSegment({
		param: "groupId",
		type: "param",
		validation: "number",
	});

	return {
		/** Find & count some {@link GroupDto groups} */
		findAndCount:
			base.get<(query: GroupQueryDto) => Promise<GroupQueryResultsDto>>(),
	} as const satisfies HttpRoute.Definitions;
}
