import {
	OrganizationUserCreateDto,
	OrganizationUserDto,
	OrganizationUserQueryResultsDto,
	OrganizationUserUpdateDto,
} from "~/common/organization/user/dtos";
import { UserDto, UserQueryDto } from "~/common/user/dtos";

import { apiEndpoints } from "../endpoints";
import {
	CustomUseMutationOptions,
	CustomUseQueryOptions,
} from "../reactQueryConfig";
import { FetchError } from "../utils/FetchError";

export const organizationUserRepository = {
	create: (
		orgId: number,
	): CustomUseMutationOptions<
		OrganizationUserDto,
		FetchError,
		OrganizationUserCreateDto
	> => ({
		mutationFn: body => apiEndpoints.organizationUser.create(orgId, body),
		mutationKey: [],
	}),
	findAndCount: (
		query: UserQueryDto,
		orgId: number,
	): CustomUseQueryOptions<OrganizationUserQueryResultsDto, FetchError> => ({
		queryFn: () => apiEndpoints.organizationUser.findAndCount(query, orgId),
		queryKey: ["user", "userFindAndCount"],
	}),
	findById: (
		userId: UserDto["_id"],
		orgId: number,
	): CustomUseQueryOptions<OrganizationUserDto, FetchError> => ({
		queryFn: () => apiEndpoints.organizationUser.findById(userId, orgId),
		queryKey: ["user", "userFindById", userId],
	}),
	updateUserStatus: (
		userId: UserDto["_id"],
		orgId: number,
	): CustomUseMutationOptions<
		void,
		FetchError,
		OrganizationUserUpdateDto
	> => ({
		mutationFn: (body: OrganizationUserUpdateDto) =>
			apiEndpoints.organizationUser.updateById(userId, orgId, body),
		mutationKey: [],
	}),
};
