import {
	Mutation,
	MutationCache,
	MutationMeta,
	QueryCache,
	QueryClient,
	QueryMeta,
	UseMutationOptions,
	UseQueryOptions,
} from "@tanstack/react-query";
import { TFunction } from "i18next";
import { AppRouterInstance } from "next/dist/shared/lib/app-router-context.shared-runtime";

import {
	handleGenericMutationErrors,
	handleGenericMutationSuccess,
	handleGenericQueryErrors,
	handleHTTPStatus,
} from "./utils";
import { FetchError } from "./utils/FetchError";

export const apiRootPath = "/api/";

interface CustomMutationMeta extends MutationMeta {
	disableDefaultSuccessHandler?: boolean;
	errorMessage?: string;
	onError?: (
		error: unknown,
		variables: unknown,
		context: unknown,
		mutation: Mutation<unknown, unknown, unknown, unknown>,
		router: AppRouterInstance,
		t: TFunction<"translation", undefined>,
	) => void;
	onSuccess?: (
		data: unknown,
		variables: unknown,
		context: unknown,
		mutation: Mutation<unknown, unknown, unknown, unknown>,
		router: AppRouterInstance,
		t: TFunction<"translation", undefined>,
	) => void;
	successMessage?: string;
}

/** Custom options for useMutation */
export interface CustomUseMutationOptions<FrontEntity, FetchError, FrontBody>
	extends UseMutationOptions<FrontEntity, FetchError, FrontBody> {
	meta?: CustomMutationMeta;
}

interface CustomQueryMeta extends QueryMeta {
	onError?: (
		error: unknown,
		query: unknown,
		router: AppRouterInstance,
		t: TFunction<"translation", undefined>,
	) => void;
	onSuccess?: (
		data: unknown,
		query: unknown,
		router: AppRouterInstance,
		t: TFunction<"translation", undefined>,
	) => void;
}

/** Custom options for useQuery */
export interface CustomUseQueryOptions<FrontEntity, FetchError>
	extends UseQueryOptions<FrontEntity, FetchError, FrontEntity> {
	meta?: CustomQueryMeta;
}

const defaultRetryMutation = 0;
const defaultRetryQuery = 0;

export const ReactQueryConfig = (
	router: AppRouterInstance,
	t: TFunction<"translation", undefined>,
): QueryClient =>
	new QueryClient({
		defaultOptions: {
			mutations: {
				retry: (failureCount: number, error: Error) => {
					handleHTTPStatus(
						(error as FetchError).response?.status,
						router,
					);
					return failureCount < defaultRetryMutation;
				},
			},
			queries: {
				retry: (failureCount: number, error: Error) => {
					handleHTTPStatus(
						(error as FetchError).response?.status,
						router,
					);
					return failureCount < defaultRetryQuery;
				},
				staleTime: 60 * 1000,
			},
		},
		mutationCache: new MutationCache({
			onError: (error, variables, context, mutation) => {
				if (mutation?.meta?.onError) {
					(mutation.meta as CustomMutationMeta).onError?.(
						error as FetchError,
						variables,
						context,
						mutation,
						router,
						t,
					);
				} else {
					handleGenericMutationErrors(
						error as FetchError,
						variables,
						context,
						mutation,
						router,
						t,
					);
				}
			},
			onSuccess: (data, variables, context, mutation) => {
				if (mutation?.meta?.onSuccess) {
					(mutation.meta as CustomMutationMeta).onSuccess?.(
						data,
						variables,
						context,
						mutation,
						router,
						t,
					);
					return;
				} else if (mutation.meta?.disableDefaultSuccessHandler) {
					return;
				}
				void handleGenericMutationSuccess(
					data,
					variables,
					context,
					mutation,
					router,
					t,
				);
			},
		}),
		queryCache: new QueryCache({
			onError: (error, query) => {
				if (query?.meta?.onError) {
					(query.meta as CustomQueryMeta).onError?.(
						error as FetchError,
						query,
						router,
						t,
					);
				} else {
					void handleGenericQueryErrors(error, query, router, t);
				}
			},
			onSuccess: (data, query) => {
				if (query?.meta?.onSuccess) {
					(query.meta as CustomQueryMeta).onSuccess?.(
						data,
						query,
						router,
						t,
					);
				}
			},
		}),
	});
