import { Model } from "@appti/core";
import * as z from "zod";

/** Schema for an organization (commune) */
export const organizationModelSchema = Model.schema.extend({
	/** TODO: This is a temporary usage of this column, this will be replaced later */
	logoUrl: z
		.string()
		.describe(
			"The URL logo of commune (will be replaced later by another way to retrieve logo)",
		),
	name: z.string().min(3).describe("Name of the organization"),

	// TODO: web site, address, ...
});
export type OrganizationModel = z.infer<typeof organizationModelSchema>;
