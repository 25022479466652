import {
	AUTH_HTTP_CONFIG,
	AuthLogin,
	AuthPasswordReset,
	AuthPasswordSet,
	AuthProfile,
	AuthRefresh,
	AuthSuccess,
	AuthPasswordResetByEmail,
} from "~/common/auth";

import { RequestMethod, fetchRequest } from "../utils/utils";

export const apiAuthEndpoints = {
	emailReset: (
		body: AuthPasswordResetByEmail.Dto,
	): Promise<AuthPasswordResetByEmail.Dto> =>
		fetchRequest<
			AuthPasswordResetByEmail.Dto,
			AuthPasswordResetByEmail.Dto,
			AuthPasswordResetByEmail.Dto
		>({
			body,
			endpoint: AUTH_HTTP_CONFIG.routes.passwordReset.path({
				email: body.email,
			}),
			method: RequestMethod.POST,
		}),
	getProfile: (): Promise<AuthProfile.Dto> =>
		fetchRequest<AuthProfile.Dto, AuthProfile.Dto>({
			endpoint: AUTH_HTTP_CONFIG.routes.getProfile.path({}),
			method: RequestMethod.GET,
		}),
	login: (body: AuthLogin.Dto): Promise<AuthSuccess.Dto> =>
		fetchRequest<AuthSuccess.Dto, AuthSuccess.Dto, AuthLogin.Dto>({
			body,
			endpoint: AUTH_HTTP_CONFIG.routes.login.path({}),
			method: RequestMethod.POST,
		}),
	logout: (): Promise<null> =>
		fetchRequest<null, null>({
			endpoint: AUTH_HTTP_CONFIG.routes.logout.path({}),
			method: RequestMethod.POST,
		}),
	passwordSet: (body: AuthPasswordSet.Dto): Promise<AuthPasswordSet.Dto> =>
		fetchRequest<
			AuthPasswordSet.Dto,
			AuthPasswordSet.Dto,
			AuthPasswordSet.Dto
		>({
			body,
			endpoint: AUTH_HTTP_CONFIG.routes.passwordSet.path({}),
			method: RequestMethod.PUT,
		}),
	refresh: (body: AuthRefresh.Dto): Promise<AuthSuccess.Dto> =>
		fetchRequest<AuthSuccess.Dto, AuthSuccess.Dto, AuthRefresh.Dto>({
			body,
			endpoint: AUTH_HTTP_CONFIG.routes.refresh.path({}),
			method: RequestMethod.POST,
		}),
	reset: (body: AuthPasswordReset.Dto): Promise<AuthPasswordReset.Dto> =>
		fetchRequest<
			AuthPasswordReset.Dto,
			AuthPasswordReset.Dto,
			AuthPasswordReset.Dto
		>({
			body,
			endpoint: AUTH_HTTP_CONFIG.routes.passwordSet.path({}),
			method: RequestMethod.POST,
		}),
};
