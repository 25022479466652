import * as z from "zod";

import { BaseModelOmit, createSchema, DenormalizeError } from "./base";
import { SchemaValidationOptions } from "../input.validation-schema";

/** The type of this input */
export const TYPE = "text";
/** Validation schema for {@link Model} */
export const schema = createSchema(TYPE).extend({
	area: z.boolean().default(false).describe("Is this input an area text?"),
	maxLength: z
		.number()
		.nullable()
		.default(null)
		.describe("Maximum accepted value"),
	minLength: z
		.number()
		.nullable()
		.default(null)
		.describe("Minimum accepted length"),
});

/** Input of type: text */
export type Model = z.infer<typeof schema>;

/**
 * Create a zod validation schema depends on given input
 *
 * @param input rules validation
 * @param options schema options
 * @returns schema generated by given input
 */
export const createInputSchema = (
	input: BaseModelOmit<Model>,
	options: SchemaValidationOptions = { skipRequired: false },
) => {
	let schema = z.string();
	if (input.maxLength) {
		schema = schema.max(input.maxLength);
	}
	if (input.minLength) {
		schema = schema.min(input.minLength);
	}

	return input.required && !options.skipRequired
		? schema.min(1)
		: schema.optional().nullable();
};

/**
 * Normalize input to a string -
 * String doesn't really need to be normalized right now
 *
 * @param value value to normalize
 * @returns a normalized string
 *
 * @throws error denormalization errors
 */
export function denormalize(value: string): string {
	try {
		return JSON.parse(value) as string;
	} catch {
		throw new DenormalizeError(
			`Cannot normalize "${value}" to a string. Expected a string like "string".`,
		);
	}
}
