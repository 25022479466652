import { HttpRoute } from "@appti/core";

import { ApplicationGroupCheckDto } from "./dtos";

/**
 * Create routes definitions for `group-check` features
 *
 * @param base of the HttpRoute builder
 * @returns route definitions
 */
export function createGroupCheckHttpDefinitions<
	const BASE extends HttpRoute.BuilderAny,
>(base: BASE) {
	return {
		create: base.post<
			() => Promise<ApplicationGroupCheckDto | undefined>
		>(),
		findAndCount: base.get<() => Promise<ApplicationGroupCheckDto[]>>(), // TODO : ApplicationGroupCheckQueryResultsDto
	} as const satisfies HttpRoute.Definitions;
}
