import { HttpRoute } from "@appti/core";

import { ApplicationGroupPermissionQueryResultDto } from "./dto/group-permission.query-result.dto";
import { ApplicationGroupPermissionQueryDto } from "./dto/group-permission.query.dto";

/**
 * Create routes definitions for `application-template` features
 *
 * @param base of the HttpRoute builder
 * @returns route definitions
 */
export function createGroupPermissionHttpDefinitions<
	const BASE extends HttpRoute.BuilderAny,
>(base: BASE) {
	return {
		findAndCount:
			base.get<
				(
					query: ApplicationGroupPermissionQueryDto,
				) => Promise<ApplicationGroupPermissionQueryResultDto>
			>(),
	} as const satisfies HttpRoute.Definitions;
}
