import * as z from "zod";

import { createSchema } from "./base";
import { Input } from "../../input";

/** The type of this input */
export const TYPE = "input";

/** Validation schema for {@link Model} */
export const schema = createSchema(TYPE).extend({
	input: Input.schema.describe("The input to fill"),
	label: z.string().describe("Label for the input"),
});

/** Application element that is an input */
export type Model = z.infer<typeof schema>;

// Identity between Model and DTO
export type { Model as Dto };
export { schema as dtoSchema };
