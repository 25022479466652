import { Id, toast, ToastOptions } from "react-toastify";

import { Toast, ToastProps, ToastVariants } from "../components/Toast";

/**
 *
 * @param param0 options for the toast
 * @param param0.autoClose Time in milliseconds before the toast is automatically closed
 * @param param0.onClose Callback when the toast is closed
 * @param param0.variant The variant of the toast
 * @returns void
 */
export function showToast({
	autoClose,
	onClose,
	variant = ToastVariants.INFO,
	...props
}: Pick<ToastOptions, "autoClose"> & ToastProps): Id {
	return toast(
		({ closeToast }) => (
			<Toast {...props} closeToast={closeToast} variant={variant} />
		),
		{
			autoClose,
			closeButton: false,
			icon: false,
			position: "top-right",
			toastId: props.title,
			type: variantToType[variant],

			// onClose is not triggered by the Toast component itself but here to catch all possible ways of closing the toast
			onClose,

			bodyStyle: {
				borderRadius: 8,
				margin: 0,
				padding: 0,
			},

			style: {
				backgroundColor: "transparent",
				borderRadius: 8,
				minHeight: 0,
				padding: 0,
			},

			// The toast progress bar color is controlled by CSS vars located in global.css
		},
	);
}

type VariantToType = {
	[variant in ToastVariants]: ToastOptions["type"];
};
const variantToType: VariantToType = {
	[ToastVariants.ERROR]: "error",
	[ToastVariants.INFO]: "info",
	[ToastVariants.SUCCESS]: "success",
};
